.node-description-background-black{
	background-color: @grey;
	margin: 80px 0 67px;
	padding: 75px 0;
	color: @white;
	@media screen and (max-width: 767px){
		margin: 50px 0;
	}
	.left-border{
		position: relative;
    	padding: 0 50px 0px 120px;
    	@media screen and (max-width: 767px){
		    padding:0;
		}
		@media (min-width: 768px) and (max-width: 991px){
			padding: 0 40px;
		}
		&:before{
			content: '';
		    height: 110px;
		    width: 10px;
		    background-color: #fff;
		    position: absolute;
		    left: 50px;
		    @media screen and (max-width: 767px){
		    	left: -10px;
		    }
		    @media (min-width: 768px) and (max-width: 991px){
				left: 0;
			}
		}
		h1{
			margin: 0;
			margin-bottom: 20px;
			font-weight: 700;
			color: #fff;
		    max-width: 260px;
		    @media screen and (max-width: 767px){
		    	padding-left: 10px;
		    }
		}
		.field-name-body{
			color: #A7A7A7;
		}
	}
}
.header-section{
	position: relative;
    height: auto;
    overflow: hidden;
	.header-image{
		width: 68%;
    	float: right;
    	img{
    		width: 100%;
    		max-height: calc(100vh - 25vh);
    	}
	}
}
.section-information-project{
	padding: 50px 0 0;
	.field-name-title{
		h2{
			text-align: center;
			font-weight: 600;
			margin-top: 0;
			margin-bottom: 20px;
		}		
	}
}
.col-appartement{
	position: relative;
	margin-bottom: 82px;
	&:hover{
		-webkit-box-shadow: 0px 2px 5px 0px rgba(113,113,113,0.8);
		-moz-box-shadow: 0px 2px 5px 0px rgba(113,113,113,0.8);
		box-shadow: 0px 2px 5px 0px rgba(113,113,113,0.8);
	}
	@media screen and (max-width: 991px){
		margin-bottom: 0;
	}
	.app-image{
		position: relative;
		&:hover{
			.app-price{
				max-height: 100%;
			}
		}
		.field-type-image{
			.content{
				img{
					height: 270px;
					object-fit: contain;
				}
			}
		}
		.app-price{
			position: absolute;
		    bottom: 0;
		    width: 100%;
		    text-align: center;		    
		    color: #fff;
		    background-color: #00000070;
		    max-height: 0;
			overflow: hidden;
			/* Set our transitions up. */
			-webkit-transition: max-height 0.8s;
			-moz-transition: max-height 0.8s;
			transition: max-height 0.8s;
			@media screen and (max-width: 991px){
				max-height: 100%;
			}
			.field-name-field-prix{
				padding: 20px;
				margin: 0;
				&.field-label-inline{
					.field-label,.field-items{
						float: none;
						font-size: 18px;
					    font-weight: 600;
					    display: inline-block;
					}
				}
			}
		}
	}
	.app-content{
		padding: 20px;
		height: 250px;
		@media screen and (max-width: 767px){
			text-align: center;
			height: auto;
		}
		.app-title{
			margin: 0;
			margin-bottom: 20px;
		}
	}
}
.node-webform-projet-sur-mesure {
	h1 {
		margin: 0;
	    margin-bottom: 30px;
	    font-weight: 900;
	    color: #4A4A4A;
	    max-width: 260px;
	}
	p {
		margin-bottom: 65px;
	}
	.group-bloc-webform {
		position:relative;
		.field-item {
			max-width: 50%;
    		@media screen and (max-width: 991px){
    			max-width: 100%;
    		}
		}
		.webform-client-form {
			max-width: 600px;
		    background: #3a3a3c;
		    padding: 60px 50px 30px;
		    position: absolute;
		    top: -30px;
		    left: 48%;
		    @media screen and (max-width: 767px){
		    	max-width: 100%;
			    background: #3a3a3c;
			    padding: 20px 20px 90px;
			    position: relative;
			    top: 0;
			    left: 0;
			    margin: auto;
		    }
		    @media (min-width: 992px) and (max-width: 1200px){
		    	padding: 20px 30px;
			    left: 49%;
			    top: -60px;
		    }
		    @media (min-width: 768px) and (max-width: 991px){
			    padding: 20px 40px 80px;
			    left: auto;
			    right: auto;
			    position: relative;
			    margin: auto;
			    top: 0;
		    }
		    .form-control {
			    display: block;
			    width: 100%;
			    height: 45px;
			    padding: 6px 0px;
			    font-size: 14px;
			    line-height: 1.42857143;
			    color: #fff;
			    background-color: #3a3a3c;
			    background-image: none;
			    border: none;
			    border-bottom: 1px solid #fff;
			    border-radius: unset;
			    box-shadow: none;
			    transition: none;
			    font-family: 'Avenir';
			    font-weight: 300;
			    letter-spacing: 0.2px;
			}
			.form-actions {
				.btn-primary {
				    color: #fff;
				    background-color: transparent;
				    border-color: unset;
				    font-family: 'Avenir';
				    font-weight: bold;
				    letter-spacing: 0.2px;
				}
				.btn {
					margin-top: 30px;
				    border: none;
				    border-radius: unset;
				    float: right;
				}
			}
		}
		.field-name-field-image{
			img{
				min-height:475px;
				object-fit: cover;
				@media screen and (max-width: 991px){
					min-height: initial;
    				object-fit: unset;
				} 
			}
		}
	}
}
.webform-submit{
	position:relative;
	padding: 5px 40px 5px 0;
	color: white;
    background-color: transparent;
    font-weight: bold;
    opacity: 0.7;
	&:after{
		content: '\e905';
		position: absolute;
		right: 0;
		top: 10px;
		font-family: 'icomoon';
		transform: rotate(270deg);
		font-size: 10px;
	}
	&:hover{
		color: white;
    	background-color: transparent;
    	opacity: 1;
	}
}
.node-webform-for-block{
	padding: 100px 0;
    background: #3a3a3c;
    color: #fff;
    margin: 75px 0 0;
    .group-content-webform{
    	.field-name-title{
			position: relative;
	    	padding: 0 50px 0px 140px;
	    	@media screen and (max-width: 767px){
	    		padding: 0 0 0 30px;
	    	}
			&:before{
				content: '';
			    height: 110px;
			    width: 10px;
			    background-color: #fff;
			    position: absolute;
			    left: 50px;
			    @media screen and (max-width: 767px){
			    	left: 0;
			    }
			}
			h1{
				margin: 0;
			    margin-bottom: 30px;
			    color: #fff;
			    max-width: 410px;
			    text-transform: uppercase;
			    @media screen and (max-width: 767px){
	    			max-width: 250px;
			    }
			}
		}
		.field-name-body{
			padding: 0 50px 50px 140px;
			@media screen and (max-width: 767px){
	    		padding: 0 0 0 30px;
	    	}
			.field-item{
				color: #A7A7A7;
				letter-spacing: 0.2px;
				font-weight:300;
			}
		}
		.webform-client-form{
			padding: 0 50px 0 140px;
		    @media screen and (max-width: 767px){
			    padding: 60px 20px 30px;
		    }
		    .form-control {
			    display: block;
			    width: 100%;
			    height: 45px;
			    padding: 6px 0px;
			    font-size: 14px;
			    line-height: 1.42857143;
			    color: #fff;
			    background-color: #3a3a3c;
			    background-image: none;
			    border: none;
			    border-bottom: 1px solid #fff;
			    border-radius: unset;
			    box-shadow: none;
			    transition: none;
			    font-family: 'Avenir';
			    font-weight: 300;
			    letter-spacing: 0.2px;
			}
			.form-actions {
				.btn-primary {
				    color: #fff;
				    background-color: transparent;
				    border-color: unset;
				    font-family: 'Avenir';
				    font-weight: 900;
				    letter-spacing: 0.2px;
				}
				.btn {
					margin-top: 30px;
				    border: none;
				    border-radius: unset;
				    float: right;
				}
			}
		}
    }
}
.node-header-basic{
	position:relative;
	.field-type-image{
		img{
			width: 68%;
			float: right;
			max-height: calc(100vh - 25vh);
			@media screen and (max-width: 1200px){
				width: 100%;
				height: auto;
			}
		}
	}
	.group-container-header {
    	h1 {
    		font-size: 30px;
			&:after{
				content: "«";
			    font-size: 3.5em;
			    font-weight: 900;
			    color: #3A3A3C;
			    position: absolute;
			    left: 0;
			    top: 65px;
			    z-index: -1;
			    letter-spacing: 2.86px;
			    line-height: 53px;
			    opacity: 0.17;
			}
			@media screen and (max-width: 767px){
				font-size: 30px;
				max-width: 640px;
			}
		}
		.field-name-body{
			font-style:italic;
		}
	}
}
.page-node-19{
	.group-content{
		.field-name-title{
			.field-item{
				max-width: 680px;
    			margin: auto;
			}
		}
	}
	.node-header-basic{
		position:relative;
		.field-name-field-map-code{
			iframe{
				width: 68%;
			    float: right;
			    margin: 0;
			    max-height: calc(100vh - 25vh);
			}
		}
		.group-container-header {
			.field-name-field-r-seaux-sociaux {
				.field-item{
					padding: 0 5px;
					margin-top: 20px;
					&.even{
						display: inline-block;
						font-size: 0;
						a:before{
							content:"\f099";
							font-size: 20px;
							color: #000;
							font-family:"Font Awesome 5 brands";
						}
						&:nth-child(1) a:before{
							content:"\f39e";
							font-size: 20px;
							color: #000;
							font-family:"Font Awesome 5 brands";
						}
					}
					&.odd {
						display: inline-block;
						font-size: 0;
						a:before {
							content:"\f16d";
							font-size: 20px;
							color: #000;
							font-family:"Font Awesome 5 brands";
						}
					}
				}
			}
			.group-adresse{
				color: #7B7B79;
			    font-size: 14px;
			    letter-spacing: 0.2px;
			    line-height: 20px;
			}
		}
	}
}
.paragraphs-items-field-element-carousel{
	padding: 26px 75px 0;
	@media screen and (max-width: 767px){
		padding:0;
	}
	@media (min-width: 768px) and (max-width: 991px){
		padding:26px 0 0;
	}
	.carousel-black{
		.field-name-field-icon{
			.field-item{
				padding:20px 0;
				img{
					margin: auto;
					width: 50px;
				    height: 50px;
				    object-fit: contain;
				}
			}
		}
		.field-name-field-sous-titre{
			font-size: 12px;
			color: #FFFFFF;
			letter-spacing: 3.51px;
			text-align: center;
			line-height: 20px;
		    margin-top: 20px;
		}
	}
}
.view-id-related_residence{
	.header-section{
		padding: 0;
		@media screen and (max-width: 991px){
			height: auto;
		}
		.header-image{
			.slide-container{
				position: absolute;
			    top: 50%;
			    transform: translateX(-50%) translateY(-50%);
			    background: #fff;
			    padding: 50px 50px 50px 30px;
			    max-width: 650px;
			    z-index: 9;
			    @media screen and (max-width: 767px){
			    	position: relative;
				    top: auto;
				    bottom: 15px;
				    left: 0;
				    transform: unset;
				    background: #fff;
				    padding: 10px 15px;
				    max-width: 95%;
				    margin: 0;
			    }
			    @media (min-width: 768px) and (max-width: 991px){
			    	transform: translateY(-50%);
				    padding: 25px 40px;
				    left: 0;
				}
			    &:after {
				  	content: '';
				    position: absolute;
				    width: 150px;
				    height: 10px;
				    background: #3A3A3C;
				    top: 50%;
				    right: -80px;
				    transform: translateY(-50%);
				    display: block;
				    @media screen and (max-width: 767px){
				    	display: none;
				    }
			    }
			    .field-name-field-ville {
			    	.even{
			    		font-size: 16px;
			    		color: #7B7B7B;
			    		font-weight: 600;
			    		&:before {
			    			content: "\e914";
			    			font-size: 16px;
						    font-family: 'icomoon' !important;
						    font-weight: normal;
						    margin-right: 5px;
			    		}
			    	}
			    }
				.group-container-highlight{
					display: block;
					.field {
					    display: inline-block;
					    padding: 30px 7px 0;
					    text-align: center;
					    @media screen and (max-width: 767px){
					    	display: block;
					    	padding:20px 0 0;
					    }
					}
				}
			}
		}
	}
	.content{
		position:relative;
		.field-name-partage-{
			position: absolute;
		    bottom: -130px;
		    right: 30px;
		    display: block;
		    z-index: 9;
		    @media screen and (max-width: 767px){
		    	display: none;
		    }
		    .field-label{
		    	display: inline-block;
		    	font-size: 14px;
			    color: #595959;
			    letter-spacing: 0.2px;
			    font-weight: 200;
		    }
		    .field-items{
		    	display: inline-block;
		    	.field-item{
		    		ul{
		    			padding:0;
		    			li{
		    				list-style-type: none;
		    				padding: 0 3px;
		    				display: inline-block;
		    				a{
		    					.fa-facebook-f:before, .fa-instagram:before, .fa-twitter:before {
								    font-size: 15px;
								}
		    				}
		    			}
		    		}
		    	}
		    }
		}
	}
}

.pagination{
	margin: 0;
	li{
		font-size: 16px;
	    color: #000000;
	    letter-spacing: 0.23px;
		span, a{
			margin: 0 10px;
			border-radius:0;
			color: #000;
			border:3px solid transparent;
			background-color:transparent;
			&:hover{
				background-color: transparent!important;
			    border: 3px solid #000!important;
			    color: #000!important;
			    border-radius: unset!important;
			}
		}
	}
	.next{
		a{
			border:3px solid transparent;
		    font-size: 0;
		    margin: 0;
		    padding: 0;
		    &:hover{
		    	color: #000;
		    	border:3px solid transparent!important;
		    }
			&:before{
				content: '\e905';
			    font-family: 'icomoon';
			    transform: rotate(270deg);
			    font-size: 10px;
			    position: absolute;
			    top: 0;
			    left: 0;
			    padding: 10px 0;
			}
		}
	}
	.prev{
		a{
			border:3px solid transparent;
		    font-size: 0;
		    margin: 0;
		    padding: 0;
		    &:hover{
		    	color: #000;
		    	border:3px solid transparent!important;
		    }
			&:before{
				content: '\e905';
			    font-family: 'icomoon';
			    transform: rotate(90deg);
			    font-size: 10px;
			    position: absolute;
			    top: 0;
			    left: auto;
			    right: 0;
			    padding: 10px 0;
			}
		}
	}
	.pager-last, .pager-first{
		display: none;
	}
	.active{
		span{
			background-color:transparent;
			border: 3px solid #000;
			color: #000;
		}
	}
}

#block-views-e192f8e0a562ba4581a8ad34c894202a{
	.header-section{
		display:none;
	}
}	
.page-node-27{
	.node-appartement{
		margin: 0;
	}
}
.node-appartement{
	margin: 82px 0;
	.batiment{
		.field-name-title{
			margin-bottom: 35px;
		}
		.group-contenu-tabs{
			.field-group-htabs{
				.field{
					font-size: 16px;
				}
			}
		}
	}
}
.front{
	#block-views-home-slider-block-home-slider{
		.view-footer{
			.scroll{
				position:relative;
				display: block;
				@media screen and (max-width: 991px){
					display: none;
				}
				.back-down{
					position: absolute;
				    left: 0;
				    bottom: 150px;
					.btn-back-down{
						color: #151515;
					    letter-spacing: 0.2px;
					    font-weight: 900;  
						&:after{
							content: '\e905';
						    color: #000;
						    position: absolute;
						    bottom: -35px;
						    left: -7px;
						    font-family: 'icomoon' !important;
						}
					}
					@media (min-width: 992px) and (max-width: 1400px){
					    left: 5%;
					    bottom: 50px;
					}
				}
			}
		}
	}
}
#block-block-4{
	.back-top{
		position: absolute;
	    left: 0;
	    top: 100px;
	    .btn-back-top{
	    	color: #151515;
		    letter-spacing: 0.2px;
		    font-weight: 900; 
			&:before{
				content: '\e905';
			    color: #000;
			    position: absolute;
			    top: -40px;
			    right: -15px;
			    font-family: 'icomoon' !important;
			    transform: rotate(180deg);
			}
	    }
	}
}
.not-front.node-type-page{
	.scroll-content{
		.back-down{
		    position: absolute;
		    bottom: 15%;
		    left: 5%;
		    display: block;
		    @media screen and (max-width: 767px){
		    	display: none;
		    }
		    @media (min-width: 768px) and (max-width: 1200px){
		    	position:absolute !important;
		    	bottom: 40px;
    			left: 35px;
		    }
			.btn-back-down{
				color: #151515;
			    letter-spacing: 0.2px;
			    font-weight: 900;  
				&:before{
					content: '\e905';
				    color: #000;
				    position: absolute;
				    bottom:-35px;
				    right: -10px;
				    font-family: 'icomoon' !important;
				}
			}
		}
	}
}
#block-block-5{
	position: fixed;
    width: 30px;
    display: block;
    z-index: 999999;
    top: 50%;
    transform: translateY(-50%);
    @media screen and (max-width: 767px){
    	display: none;
    }
	.reseaux-sociaux{
		ul{
			padding: 5px;
    		margin: 0;
			li{
				list-style-type: none;
			    text-align: center;
			    background: #fff;
			    padding: 0;
			    width: 35px;
			    height: 35px;
			    border-radius: 25px;
			    margin: 10px 0;
			    opacity: 1;
			    &:hover{
			    	opacity: 0.7;
			    }
			    a{
			    	.fab{
			    		padding:7px 0;
			    	}
			    }
			}
		}
	}
}
.details{
	.content_en_bref{
		.field-name-field-image{
			display: none;
		}
	}
}
#block-views-projets-projets-home{
	.view-id-projets{
		.owl-carousel-project-home{
			.owl-controls{
				.owl-pagination{
					.owl-page{
						span{
							background: transparent;
						    border: 1px solid #D8D8D8;
						}
					}
					.active{
						span{
							background: #D8D8D8;
						}
					}
				}
			}
			.node-projets{
				background-color: #3a3a3c;
			}
		}
	}
}
.view-id-pages_projets{
	.node-projets{
		background-color: #3a3a3c;
	}
}
.button-right-arrow {
    color: #fff;
    background-color: transparent;
    border: 2px solid #fff;
    position: relative;
    bottom: auto;
    right: auto;
    z-index: 1;
    margin-top: 115px;
    &:hover{
    	background: #3a3a3c;
	    color:  #fff;
	    border: 2px solid #3a3a3c;
    }
    @media screen and (max-width: 767px){
    	position:relative;
    	bottom:auto;
    	padding: 15px 65px 15px 15px;
    }
    @media screen and (max-width: 1600px){
    	margin-top: 0; 
    }
}
#block-views-f01654fd181d38ab562384bba3cb63a0, #block-views-appartements-residence-block-1{
	.view-filters{
		.views-widget-filter-field_prix_value{
	        margin: 185px 0 65px;
		    float: none;
		    padding: 0;
			.slider-filter-processed{
				display: flex;
			    margin-bottom: 0;
			    position: relative;
			    bottom: -35px;
				.form-item{
					width: 50%;
				}
				.form-item-field-prix-value-max{
					.control-label{
						display: none;
					}
					.form-control{
						text-align: right;
						width: 100%!important;
					}
				}
			}
			.ui-slider-horizontal {
			    height: 0;
			    margin-top: 0;
			    .ui-slider-handle {
    				top: -10px;
    			}
			}
		}
	}
}
textarea::placeholder,
input::placeholder {
  color: #fff!important;
}
.page-node-4{
	.node-appartement {
	    margin: 0;
	}
	.node-projets{
		.header-section{
			.group-container-header{
				padding:80px 50px;
				&:before{
					content: '';
					background: url(../images/logo_la_cigale.png) no-repeat;
				    width: 240px;
				    height: 100px;
				    position:absolute;
				    top: 0;
				    left: 0;
				}
				@media screen and(max-width: 767px){
					padding:10px 15px;
				}
				.field-name-title{
					h1{
						max-width: 260px;
					}
				}
			}
		}
	}
	.field-name-field-terrasses:before{
		content: '\e919';
		font-size: 25px;
		color: #000;
		font-family: 'icomoon';
		display: block;
		font-weight: 100;
		@media screen and (max-width: 767px){
			display: inline-block;
			vertical-align: middle;
			padding:0 15px;
		}
	}
	.field-name-field-suite-parental:before{
		content: '\e903';
		font-size: 25px;
		color: #000;
		font-family: 'icomoon';
		display: block;
		font-weight: 100;
		@media screen and (max-width: 767px){
			display: inline-block;
			vertical-align: middle;
			padding:0 15px;
		}
	}
}
.page-search{
	.view-id-appartements_residence{
		display: block;
		.view-filters{
			width: 25%;
			float: left;
			border: 1px solid #80808070;
		    padding: 20px;
		    min-height: 385px;
		    position: relative;
		    margin: 82px 0;
		    @media screen and (max-width: 767px){
		    	margin-bottom: 20px;
		    	width: 100%;
		    }
		    .views-reset-button{
				position: absolute;
			    bottom: 0;
			    padding:0 0 20px;
			    .form-submit{
				    border: none;
				    background: transparent;
				    text-decoration: underline;
				    font-size: 12px;
				    color: #D40000;
				    letter-spacing: 0.17px;
				    margin:0;
				    font-family: 'Avenir';
				    &:hover{
				    	text-decoration: none;
				    }
				}
			}
			.views-exposed-form {
				label:nth-child(1){
				    font-size: 14px;
				    color: #000000;
				    letter-spacing: 0.2px;
				    font-weight: 600;
				    margin-bottom: 30px;
				}
				.views-widget{
					.form-control{
						display: block;
					    width: 60px;
					    height: 15px;
					    padding: 0;
					    font-size: 12px;
					    line-height: 1.42857143;
					    color: #000;
					    background-color: transparent;
					    background-image: none;
					    border: none;
					    border-radius: 0;
					    box-shadow: unset;
					}
				}
				.views-widget-filter-field_prix_value{
			        margin: 185px 0 65px;
				    float: none;
				    padding: 0;
					.slider-filter-processed{
						display: flex;
					    margin-bottom: 0;
					    position: relative;
					    bottom: -35px;
						.form-item{
							width: 50%;
						}
						.form-item-field-prix-value-max{
							.control-label{
								display: none;
							}
							.form-control{
								text-align: right;
								width: 100%!important;
							}
						}
					}
					.ui-slider-horizontal {
					    height: 0;
					    margin-top: 0;
					    .ui-slider-handle {
		    				top: -10px;
		    			}
					}
				}
			}
			.form-item-edit-field-pieces-value-1, .form-item-edit-field-pieces-value-2, .form-item-edit-field-pieces-value-3,
			.form-item-edit-field-pieces-value-4, .form-item-edit-field-pieces-value-5, .form-item-edit-field-pieces-value-6{
				display: flex;
				label {
				    display: inline-block;
				    max-width: 100%;
				    margin-bottom: 20px;
				    padding-top: 4px;
				}
			}
		}
		.view-content{
			width: 73%;
			float: left;
			@media screen and (max-width: 767px){
				width: 100%;
			}
			.col-appartement{
				.field-name-field--etage, .field-name-field-superficie,
				.field-name-field-dressing, .field-name-field-jardin, .field-name-field-orientation{
					display: flex;
					@media screen and (max-width: 767px){
						display: block;
						text-align: center;
					}
					.field-label{
						text-transform: uppercase;
						font-weight: 600;
						padding:5px 0;
					}
					.field-items{
						padding:5px 0;
					}
				}
			}
		}
		.pagination{
			margin: 0;
			li{
				font-size: 16px;
			    color: #000000;
			    letter-spacing: 0.23px;
				span, a{
					margin: 0 10px;
					border-radius:0;
					color: #000;
					border:3px solid transparent;
					background-color:transparent;
					&:hover{
						background-color:transparent;
						border: 3px solid #000;
					}
				}
			}
			.next{
				a{
					border:3px solid transparent;
				    font-size: 0;
				    margin: 0;
				    padding: 0;
				    &:hover{
				    	color: #000;
				    	border:3px solid transparent;
				    }
					&:before{
						content: '\e905';
					    font-family: 'icomoon';
					    transform: rotate(270deg);
					    font-size: 10px;
					    position: absolute;
					    top: 10px;
					    left: 0;
					}
				}
			}
			.prev{
				a{
					border:3px solid transparent;
				    font-size: 0;
				    margin: 0;
				    padding: 0;
				    &:hover{
				    	color: #000;
				    	border:3px solid transparent;
				    }
					&:before{
						content: '\e905';
					    font-family: 'icomoon';
					    transform: rotate(90deg);
					    font-size: 10px;
					    position: absolute;
					    top: 10px;
					    left: auto;
					    right: 0;
					}
				}
			}
			.pager-last, .pager-first{
				display: none;
			}
			.active{
				span{
					background-color:transparent;
					border: 3px solid #000;
				}
			}
		}
		.node-appartement{
			margin: 82px 0;
		}
	}	
}
.node-recherche-appartement{
	margin: 82px 0;
	h2{
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 25px;
	}
	.field-name-body{
		text-align: center;
	    max-width: 700px;
	    margin: auto;
	    margin-bottom: 45px;
	}
	.field-name-field-bouton-recherche{
		text-align: center;
	}
}

.view-id-recherche{
	.view-content {
	    width: 73%;
	    float: left;
	    @media screen and (max-width: 767px){
	    	width: 100%;
	    }
	    @media (min-width: 768px) and (max-width: 991px){
	    	width: 55%;
	    }
	    .views-bootstrap-grid-plugin-style{
	    	.col-appartement {
			    max-width: 400px;
			    margin: 0 15px 82px;
			    padding: 0;
			    @media screen and (max-width: 767px){
			    	padding: 0 15px;
			    	max-width: initial;
			    	margin: 30px 0;
			    }
			    @media (min-width: 768px) and (max-width: 1199px){
			    	padding: 0 15px;
			    	max-width: initial;
			    	margin: 0 0 30px;
			    }
			    .app-image{
			    	img{
			    		height: 350px;
			    	}
			    	.app-price{
			    		.field-name-field-prix {
			    			.even{
							    font-size: 18px;
							    font-weight: 600;
			    			}
			    		}
			    	}
			    }
			    .app-content{
			    	.row{
			    		padding:10px 0;
			    		@media screen and (max-width: 767px){
			    			padding:0 0 80px;
			    		}
			    	}
			    	h4{
			    		font-size: 13px;
			    		font-weight: normal;
					    margin: 0;
					    color: #000;
					    text-align: left;
					    line-height: normal;
					    @media screen and (max-width: 767px){
					    	text-align: center;
					    }
			    	}
			    	.field-name-field-nb-etages, .field-name-field-orientation,
			    	.field-name-field-superficie, .field-name-field-jardin{
			    		display: flex;
					    font-size: 14px;
					    text-transform: uppercase;
					    padding: 5px 0;
					    @media screen and (max-width: 767px){
					    	display: block;
					    	text-align: center;
					    }
					    &:before{
					    	font-size: 12px;
						    font-weight: 600;
						    font-family: 'Avenir';
						    padding: 0 5px 0 0;
						    position: relative;
						    top: 2px;
					    }
			    	}
			    	.field-name-field-nb-etages{
			    		&:before{
			    			content: 'étages:';
			    		}
			    	}
			    	.field-name-field-orientation{
			    		&:before{
			    			content: 'orientation:';
			    		}
			    	}
			    	.field-name-field-superficie{
			    		&:before{
			    			content: 'superficie:';
			    		}
			    	}
			    	.field-name-field-jardin{
			    		&:before{
			    			content: 'jardin:';
			    		}
			    	}
			    	.field-name-field-fichier-plan{
					    position: absolute;
					    bottom: 68px;
					    @media screen and (max-width: 767px){
					    	bottom:60px;
					    	left: 0;
					    	right: 0;
					    }
					    a{
					    	color: #000;
					    	font-weight:600;
					    }
					}
			    	.lien-desc{
			    		position: absolute;
					    bottom: 50px;
					    right: 35px;
					    @media screen and (max-width: 767px){
					    	bottom:30px;
					    	left: 0;
					    	right: 0;
					    }
			    		a{
			    			color: #3a3a3c;
						    background-color: transparent;
						    border: 2px solid #3a3a3c;
						    position: relative;
						    bottom: auto;
						    right: auto;
						    text-align: center;
						    display: inline-block;
						    padding: 15px 60px 15px 15px;
						    font-size: 14px;
						    font-weight: 900;
						    letter-spacing: 0.2px;
						    text-decoration: none;
						    text-transform: uppercase;
						    -webkit-transition: all 0.3s;
						    -moz-transition: all 0.3s;
						    transition: all 0.3s;
						    &:hover{
						    	background: #3a3a3c;
							    color: #fff;
							    border: 2px solid #3a3a3c;
							    text-decoration: none;
						    }
						    &:before{
						    	content: '\e905';
							    position: absolute;
							    right: 15px;
							    top: 17px;
							    font-family: 'icomoon';
							    transform: rotate(270deg);
							    font-size: 10px;
						    }
			    		}
			    	}
			    }
			}
	    }
	}
	.view-filters{
		width: 20%;
	    float: left;
	    border: 1px solid #80808070;
	    padding: 20px;
	    min-height: 385px;
	    position: relative;
	    margin-right: 50px;
	    @media screen and (max-width: 767px){
	    	width: 100%;
	    	margin-right: 0;
	    }
	    @media (min-width: 768px) and (max-width: 991px){
	    	width: 35%;
	    }
		.views-widget-filter-field_prix_value{
	        margin: 185px 0 65px;
		    float: none;
		    padding: 0;
			.slider-filter-processed{
				display: flex;
			    margin-bottom: 0;
			    position: relative;
			    bottom: -35px;
				.form-item{
					width: 50%;
				}
				.form-item-field-prix-value-max{
					.control-label{
						display: none;
					}
					.form-control{
						text-align: right;
						width: 100%!important;
					}
				}
			}
			.ui-slider-horizontal {
			    height: 0;
			    margin-top: 0;
			    .ui-slider-handle {
    				top: -10px;
    			}
			}
		}
		.views-exposed-form .views-widget .form-control {
		    display: block;
		    width: 60px;
		    height: 15px;
		    padding: 0;
		    font-size: 12px;
		    line-height: 1.42857143;
		    color: #000;
		    background-color: transparent;
		    background-image: none;
		    border: none;
		    border-radius: 0;
		    box-shadow: unset;
		}
		.views-reset-button {
		    position: absolute;
		    bottom: 0;
		    padding: 0 0 20px;
		    .form-submit {
			    border: none;
			    background: transparent;
			    text-decoration: underline;
			    font-size: 12px;
			    color: #D40000;
			    letter-spacing: 0.17px;
			    margin: 0;
			    font-family: 'Avenir';
			}
		}
	}
}

.view-id-recherche .view-filters .form-item-edit-field-pieces-value-1 , 
.view-id-recherche .view-filters .form-item-edit-field-pieces-value-2 ,
.view-id-recherche .view-filters .form-item-edit-field-pieces-value-3 , 
.view-id-recherche .view-filters .form-item-edit-field-pieces-value-4 , 
.view-id-recherche .view-filters .form-item-edit-field-pieces-value-5 , 
.view-id-recherche .view-filters .form-item-edit-field-pieces-value-6  {
	display: flex;
	label{
		display: inline-block;
	    max-width: 100%;
	    margin-bottom: 20px;
	    padding-top: 4px;
	}
}
.same-residence{
	.col-appartement{
		.app-image{
			.field-type-image{
				.content img{
					cursor: pointer;
				}
			}
		}
	}
}
.content_en_bref{
	.content{
		position: relative;
		img{
			cursor: pointer;
			max-height: 500px;
		    object-fit: contain;
		}
	}
}
.qui-sommes-nous{
	.node-recherche-appartement {
	    margin: 64px 0 82px;
	}
	.button-right-arrow {
	    color: #3a3a3c;
	    background-color: transparent;
	    border: 2px solid #3a3a3c;
	    bottom: auto;
	    right: auto;
	    &:hover{
	    	background-color:#3a3a3c;
	    	color: #fff;
	    }
	}
}
