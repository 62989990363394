/**
 * Variable Overrides.
 *
 * Modify this file to override the Bootstrap Framework variables. You can copy
 * these variables directly from ../bootstrap/less/variables.less to this file.
 */

// Set the proper directory for the Bootstrap Glyphicon font.
@icon-font-path: '../bootstrap/fonts/';

@brand-black: #232729;
@brand-green: #67AB51;
@brand-green-lite: #E8F3E6;
@brand-green-darker: #176B37;
@brand-gray-lite:#EFEEED;

@font-family-sans-serif : "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-base:        @font-family-sans-serif;

@font-size-small:10px;
@font-size--medium: 12px;
@font-size--large: 25px;
