.batiment{
	h2{
    	padding-left: 10px;
	    padding-bottom: 0;
	    font-weight: 800;
	}
	.field-item{
		a{
		    background: black;
		    width: 100%;
		    color: white;
		    font-size: 16px;
		    display: block;
		    text-align: center;
		    line-height: 40px;
		    font-weight: 900;
		    text-transform: uppercase;
		    margin-top: 10px;
		    @media screen and (max-width:991px){
		    	max-width: 230px;
		    	margin: auto;
		    	margin-top: 40px;
		    }
		    @media (min-width: 992px) and (max-width: 1199px){
		    	font-size: 12px;
		    }
			em{
				font-style:normal; 
			}
		}
		.file{
			.file-size,
			.file-icon{
				display: none;
			}
		}
	}
	.field-name-field-prix{
		display: flex;
	    text-align: right;
	    font-size: 24px;
	    font-weight: 900;
	    top: 0;
    	color: #000000;
		letter-spacing: 0.34px;
		@media screen and (max-width: 991px){
			max-width: 150px;
		    margin: auto;
		    margin-top: 30px;
		    margin-bottom: 30px;
		    text-align: center;
		    position: relative !important;
		    top: 10px !important;
		    margin: auto;
		    max-width: 230px;
		}   
    	.field-items{
    		position: relative;
    		&:after{
    			content: 'en TTc';
			    font-size: 12px;
			    position: absolute;
			    top: 26px;
			    right: 0;
			    color: #8b7c7d;
    			font-weight: 200;

    		}
    	}
	}
	.content-bouton {
		margin-top: 100px;
		@media screen and (max-width: 991px){
			margin-top: 0;
		}
	}
	.field-name-bouton-interesse{
		margin-top: 0;
		a{
			&:before{
				position: relative;
				content: "\f11a";
				font-family: "Font Awesome 5 Free";
				font-size: 21px;
				font-weight: 100;
				left: -10px;
				top: 2px;
			}
		}
	}
	.field-name-bouton-interesse a:hover:before{
		content: "\f118";
		font-family: "Font Awesome 5 Free";
		font-size: 21px;
		font-weight: 100;
		left: -10px;
	}
	.field-name-field-fichier-plan, .field-name-field-fichier-plan-2{
		a{
			background: white;
		    border: 2px solid #3a3a3c;
		    color: #3a3a3c;
		    font-weight: 600;
			&:before{
				position: relative;
				content: "\e903";
				font-family: "icomoon";
				font-size: 21px;
				font-weight: 100;
				top: 4px;
				left: -10px;
			}
		}
	}
	.content_details{
		display: none;
		.field-type-image{
	    	margin: 20px 0 30px 0;
		}
	}
	.tabs{
		border: 2px solid #e3e3e3;
		background:#f9f9f9;
		padding: 30px 20px 50px 20px; 
		-webkit-box-shadow:0px 0px 12px 0px rgba(0, 0, 0, 0.18);
		-moz-box-shadow:0px 0px 12px 0px rgba(0, 0, 0, 0.18);
		box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.18);
		color: #4A4A4A;
		letter-spacing: 0.2px;
		&.details{
			.content_details{
				display: block;
				img{
					height: 500px;
				    object-fit: cover;
				    object-position: center;
				}
			}	
		}
		.content_en_bref{
			.field-type-image{
				margin: 30px 0;
				a{
					background:transparent;
					img{
						margin: auto;
					}
				}
			}
			.field-name-body{
				ul{
					padding: 0;
					padding-left: 15px;
				}
			}
		}
	}
	.group-footer{
		margin: 20px 0;
		.field{
			width: 25%;
			@media screen and (max-width: 991px){
				width: 32%;
	    	}
	    	@media screen and (max-width: 700px){
				width: 50%;
	    	}
			@media screen and (max-width: 450px){
				width: 100%;
	    	}
    		display: inline-table;
    		padding-top: 20px;
    		.field-label{
    			text-transform: uppercase;
			    color: #000000;
			    letter-spacing: 0.2px;
			    text-align: center;
			    display: inline-block;
    		}
    		.field-items{
    			display: inline-block;
    			.field-item{
				font-size: 14px;
				color: #000000;
				letter-spacing: 0.2px;
				text-align: center;
				font-weight: 300;
    			}
    		}
		}
	}
	.group-contenu-tabs{
		background: #e3e3e3;
		.field-group-htabs {
		    background: #e3e3e3;
		    width: 50%;
		    display: inline-block;
		    line-height: 50px;
		    text-transform: uppercase;
		    font-weight: bold;
		    padding-left: 20px;
		    cursor: pointer;
		    position: relative;
		    &.active{
		    	.field{
		    		z-index: 1;
				    position: relative;
				    color: white;
				    text-decoration: underline;
		    	}
			    &:after{
			    	content: '';
				    background: #3a3a3c;
				    position: absolute;
				    top: -5px;
				    left: -5px;
				    right: 0;
				    bottom: -5px;
			    }
		    }
		    &.group-detail{
			    &.active{
				    &:after{
					    left: 0px;
					    right: -5px;
				    }
			    }	
		    }
		}
	}
}
.same-residence{
	margin-top: 38px;
}
div.static-title{
	font-size: 35px;
    color: black;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 55px;
    @media screen and (max-width: 767px){
    	font-size: 30px;
    }
}

p.static-title{
	margin-top: 20px;
    margin-bottom: 50px;
}

.owl-theme .owl-controls .owl-buttons div.owl-next{
	text-indent: -9999px;
	opacity: 1;
	position: relative;
	background: transparent;
	&:after{
		content: '';
	    height: 1px;
	    width: 30px;
	    background: black;
	    position: absolute;
	    left: 0;
	    top: 50%;
	}	
	&:before{
		content: '';
	    position: absolute;
	    right: -9px;
	    height: 10px;
	    width: 10px;
	    border: 1px solid black;
	    top: 14px;
	    transform: rotate(45deg);
	    border-left: none;
	    border-bottom: none;
	}
}

.owl-theme .owl-controls .owl-buttons div.owl-prev{
	text-indent: -9999px;
	opacity: 1;
	position: relative;
	background: transparent;
	&:after{
		content: '';
	    height: 1px;
	    width: 30px;
	    background: black;
	    position: absolute;
	    left: -10px;
	    top: 50%;
	}	
	&:before{
		content: '';
	    position: absolute;
	    left: -9px;
	    height: 10px;
	    width: 10px;
	    border: 1px solid black;
	    top: 14px;
	    transform: rotate(45deg);
	    border-right: none;
	    border-top: none;
	}
}

.node-appartement .col-appartement img{
	cursor: pointer;
}
.col-appartement{
	.content{
		border: 1px solid #ccc;
	}
	.field-item{
		font-family: "Avenir Light";
		font-size: 13px;
		@media (min-width: 768px) and (max-width: 991px){
			font-size: 12px;
			padding-top: 1px;
		}
	}
	.app-link{
		display: flex;
		@media screen and (max-width: 767px){
			display: block;
			min-height: initial;
			margin-top: 10px;
		}
		.field-name-field-fichier-plan, .field-name-field-fichier-plan-2{
			img,
			.file-size{
				display: none;
			}
			a{
				position: absolute;
				left: 10px;
    			bottom: 36px;
			    text-align: center;
			    display: inline-block;
			    padding: 15px 33px;
			    font-size: 14px;
			    font-weight: 700;
			    text-decoration: none;
			    text-transform: uppercase;
			    transition: all 0.3s;
			    color: white;
			    background: #3a3a3b;
			    border: 2px solid #3a3a3b;
			    margin-right: 15px;
			    @media screen and (max-width: 767px){
			    	padding: 15px 35px;
				    margin-right: 0;
				    margin-bottom: 20px;
				    left: 0;
				    right: 0;
				    bottom: 5px;
				    max-width: 180px;
				    margin: auto;
				    position: relative;
			    }
			}
		}
		.field-name-node-link{
		    .button-right-arrow{
		    	position: absolute;
			    right: 10px;
			    margin-top: 33px;
		    	color: #3a3a3c;
			    border: 2px solid #3a3a3c;
			    transition: .2s ease-in-out;
			    &:hover{
			    	background: #3a3a3c;
				    color: #fff;
				    border: 2px solid #3a3a3c;
			    }
			    @media screen and (max-width: 767px){
			    	position: relative;
				    bottom: 0;
				    left: 0;
				    right: 0;
				    max-width: 180px;
				    margin: auto;
			    }
		    }
		}
	}
}

.fullscreen{
	position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background: rgba(197, 197, 197, 0.94);
    bottom: 0;
    z-index: 10000;
    cursor: pointer;
    .sous-full{
	    position: fixed;
	    background: transparent;
	    width: 100%;
	    margin: auto;
	    height: auto;
	    top: 0;
	    left: 0;
	    bottom: 0;
	    right: 0;
    	img{
			position: relative;
		    height: 100%;
		    object-fit: contain;
		    margin: auto;
	    }
	    div{
	    	position: absolute;
		    background: url(/sites/all/modules/colorbox/styles/default/images/controls.png) no-repeat -25px 0;
		    right: 24%;
		    transform: translateX(-50%);
		    top: 5px;
		    width: 25px;
		    height: 25px;
		    z-index: 9999;
		    text-indent: -9999px;
		    @media screen and (max-width: 991px){
		    	right: 0;
    			transform: unset;
		    }
	    }
    }
}
.section-information-project{
	.container{
		max-width: 1000px; 
	}
}


#block-system-main {
	.field-name-field-avantage ul li {
		list-style: none;
		margin-left: 0;
		padding-left: 16px;
		text-indent: -16px;
	}

	.field-name-field-avantage ul li:before {
		content: "✓";
		font-size: 16px;
		line-height: 16px;
		padding-right: 5px;
		vertical-align: text-bottom;
	}
	iframe{
		margin-left: 25px;
		@media screen and (max-width: 767px){
			margin-left: 0;
		}
	}
}

#block-system-main{
	.section-information-project{
		.group-container-highlight{
			.field-name-field-nb-etages, .field-name-field-nb-appartements, .field-name-field-jardin-privatif, .field-name-field-standing,
			.field-name-field-terrasses, .field-name-field-parking-soussol, .field-name-field-ascenseur, .field-name-field-suite-parental,
			.field-name-field-parking-exterieur{
				padding: 0 20px;
				@media screen and (max-width: 767px){
					padding:0;
				}
			}
		}	
	}
}
	

#block-views-f01654fd181d38ab562384bba3cb63a0 .view-id-appartements_residence .view-filters{
	width: 20%;
    margin-right: 15px;
    @media screen and (max-width: 767px){
    	width: 100%;
    	margin-right: 0;
    }
    @media (min-width: 768px) and (max-width: 991px){
    	width: 30%;
    }
}

.view-pages-projets{
	margin-top: 0;
	@media screen and (max-width: 767px){
		margin-top: 0;
	}
	.views-row{
		margin-top: 50px;
		margin-bottom: 0;
		@media screen and (max-width: 767px){
			margin-top: 0;
			margin-bottom: 0;
		}
		.container{
			padding-top: 0;
			.button{
				margin-top: 100px;
				margin-bottom: 25px;
				@media screen and (max-width: 767px){
					margin-top: 110px;
				}
				@media screen and (max-width: 1600px){
			    	margin-top: 0; 
			    }
			}
		}
		
		.field-name-field-description{
			padding-left: 0 !important;
			&:before{
				display: none;
			}
		}
		.group-project-first-section{
			position:relative;
			background-color: @grey;
			height: auto;
			.group-content{
		        position: relative;
			    margin-top: 65px;
			    max-width: 420px;
			    background-color: #FFF;
			    padding: 5px 30px 10px 20px;
			    z-index: 5;
			    @media screen and (max-width: 767px){
			    	margin-top: 0;
				    bottom: 10px;
				    left: -15px;
				    max-width: 100%;
				    padding: 20px;
			    }
			    @media (min-width: 768px) and (max-width: 1200px){
			    	position: relative;
				    bottom: 15px;
				    left: -30px;
				    max-width: 95%;
				    background-color: #FFF;
				    padding: 30px 70px 30px 50px;
				    z-index: 5;
				    margin-bottom: 0;
				    margin: 0;
			    }
			    &:after{
			    	content: '';
				    position: absolute;
				    width: 120px;
				    height: 10px;
				    background: @grey;
				    top: 50%;
				    right: -90px;
				    transform: translateY(-50%);
				    display: block;
				    @media screen and (max-width: 767px){
				    	display: none;
				    }
			    }
			    h2{
			    	font-size: 30px;
				    margin: 0;
				    font-weight: 900;
				    color: #4A4A4A;
				    letter-spacing: 3.23px;
				    line-height: 61px;
				    @media screen and (max-width: 767px){
				    	font-size: 25px;
				    	line-height: 40px;
				    }
			    }
			    .field-name-field-ville{
			    	.field-item{
			    		font-size: 14px;
					    font-weight: 600;
					    margin-bottom: 10px;
					    color: #7B7B7B;
						letter-spacing: 0.2px;
					    &:before{
					    	content: "\e914";
						    font-family: 'icomoon' !important;
							font-weight: normal;
							margin-right: 5px;
					    }
			    	}
			    }
			    p {
				    margin-bottom: 10px;
				    color: #7B7B7B;
					letter-spacing: 0.2px;
			    }
			}
			.file-image{
				img{
					position: absolute;
				    width: calc(((100vw - 1170px) / 2) + 750px);
				    max-height: calc(100vh - 40vh);
				    object-fit: unset;
				    right: 0;
				    top: -20px;
				    z-index: 1;
					@media screen and (max-width: 1200px){
				    	width: 100%;
					    height: auto;
					    position:relative;
					    top: 0;
					    object-fit: unset;
					    max-height: none;
				    }
				}
			}			
		}
		.group-project-second-section{
			position:relative;
			min-height: 470px;
			.group-second-content{
				position: relative;
				margin-top: 20px;
				margin-left: 65px;
				max-width: 550px;
			    background-color: @grey;
			    padding: 50px 100px 27px 30px;
			    z-index: 2;
			    color: @white;
			    @media screen and (max-width: 767px){
			    	position: absolute;
				    margin: 0;
				    max-width: 90%;
				    padding: 30px;
				    left: 0;
			        bottom: 0;
    				transform: translateY(20%);
			    }
			    &:after{
			    	content: '';
				    position: absolute;
				    width: 120px;
				    height: 10px;
				    background: @white;
				    top: 50%;
				    right: -90px;
				    transform: translateY(-50%);
				    display: block;
				    @media screen and (max-width: 767px){
				    	display: none;
				    }
			    }
			    .field-name-field-second-titre{
			    	.field-item{
			    		font-size: 30px;
					    margin: 0;
					    font-weight: 900;
					    color: @white;
					    letter-spacing: 3.23px;
					    line-height: 61px;
					    @media screen and (max-width: 767px){
					    	font-size: 25px;
					    	line-height: 40px;
					    }
				    }
			    }
			    .field-name-field-seconde-description{
			    	margin-bottom: 15px;
			    }
			}
			.file-image{
				img{
					position: absolute;
				    width: calc(((100vw - 1170px) / 2) + 650px);
				    height: 500px;
				    object-fit: cover;
				    right: 0;
				    top: -30px;
				    z-index: 1;
				    @media screen and (max-width: 767px){
				    	width: 90%;
					    height: auto;
				    }
				}
			}		
		}
		.bloc-icone{
			max-width: 240px;
			margin: auto;
			.field-name-field-nb-etages, .field-name-field-nb-appartements{
				width: 50%;
				font-weight: 500;
				text-align: center;
				display: inline-block;
				margin-top: 10px;
				@media screen and (max-width: 767px){
					width: 100%;
					text-align: left;
					padding: 5px 0;
					display: flex;
				}
				.even{
					padding:0;
					@media screen and (max-width: 767px){
						padding:8px 0;
					}
				}
			}
			.field-name-field-jardin-privatif,.field-name-field-standing, .field-name-field-terrasses,
			.field-name-field-parking-soussol,.field-name-field-suite-parental,
			.field-name-field-parking-exterieur, .field-name-field-ascenseur{
				display: none;
			}
		}

		.field-name-field-standing:before, 
		.field-name-field-terrasses:before, 
		.field-name-field-suite-parental:before{
			color: white;
		}

	
	}
}

.pager{
	clear: both;
}

.node-type-projets{
	.section-information-project{
		padding-top: 32px;
		h2{
			text-transform: uppercase;
		}
	}
	header.navbar-default{
		padding-bottom: 10px;
	}
	#block-system-main .section-information-project .group-container-highlight .field-name-go-appartements{
		margin:50px 0; 
		display: none;
	}
	#block-system-main {
		.field-name-field-description-emplacement,
		.field-name-field-avantage{
			margin-top: 50px;
			@media screen and (max-width: 767px){
				margin-top: 20px;
			}
		}
		.field-name-field-map-code{
			margin-bottom: 50px;
		}
		@media screen and (max-width: 767px){

		}
	}
}
.popup-lesfables{
	position:relative;
	.frame-popup{
		position: fixed;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    right: 0;
	    background: rgba(214, 214, 214, 0.91);
	    z-index: 999991;
	    iframe{
	    	width: 72.3%;
    		height: 80%;
		    position: absolute;
		    top: 50%;
		    transform: translateY(-50%);
		    left: 0;
		    right: 0;
		    margin: auto;
		    @media screen and (max-width: 767px){
		    	width: 90vw;
	    		height: 30vh;
	    		max-width: 320px;
		    }
	    }
	    #close-popup {
		    position: relative;
		    &:after{
		    	content: 'x';
			    background: black;
			    width: 30px;
			    height: 30px;
			    border-radius: 20px;
			    font-size: 20px;
			    color: #fff;
			    text-align: center;
			    position: absolute;
			    top: 5px;
			    left: 0;
			    right: 0;
			    margin: auto;
			    cursor: pointer;
		    }
		    &:hover{
			    &:after{
			    	background: red;
			    }	
			}
		}
	}
}
.btn-default:focus, .btn-default.focus {
    color: white;
    background-color: transparent;
}
.btn:focus, .btn:active:focus, .btn.active:focus, 
.btn.focus, .btn:active.focus, .btn.active.focus {
    outline: none;
    outline-offset: 0;
}
.btn-default:active:hover, .btn-default.active:hover, .open > .dropdown-toggle.btn-default:hover, 
.btn-default:active:focus, .btn-default.active:focus, .open > .dropdown-toggle.btn-default:focus, 
.btn-default:active.focus, .btn-default.active.focus, .open > .dropdown-toggle.btn-default.focus {
    color: white;
    background-color: transparent;
}
.btn:active, .btn.active {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.view-mode-search_index{
	.col-appartement{
		.app-link{
			.field-name-field-fichier-plan, .field-name-field-fichier-plan-2{
				a {
				    position: absolute !important;
    				top: auto !important;
				    left: 10px;
				    bottom: 20px;
				    text-align: center;
				    display: inline-block;
				    padding: 15px 33px;
				    font-size: 14px;
				    font-weight: 700;
				    text-decoration: none;
				    text-transform: uppercase;
				    transition: all 0.3s;
				    color: white;
				    background: #3a3a3b;
				    border: 2px solid #3a3a3b;
				    margin-right: 15px;
				    width: auto !important;
				    @media screen and (max-width: 767px){
				    	position: relative !important;
				    	padding: 15px 50px;
					    margin-right: 0;
					    margin-bottom: 20px;
					    left: 0;
					    right: 0;
					    bottom: 5px;
					    max-width: 180px;
					    margin: auto;
				    }
				}
			}
		}
	}
}