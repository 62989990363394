h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: 'Avenir';
}
h1{
	font-size: 35px;
    position: relative;
    font-weight: 900;
    color: #3A3A3C;
    letter-spacing: 0.71px;
	line-height: 53px;
	margin: 0 0 10px;
	z-index: 1;
	text-transform:uppercase;
	@media screen and (max-width: 767px){
		font-size: 30px;
	}
}
h2{
	font-size: 30px;
	font-weight: 600;
	color: #4A4A4A;
	letter-spacing: 3.23px;
	line-height: 35px;
	margin: 0;
	@media screen and (max-width: 767px){
		font-size: 25px;
	}
}
h3{
	font-size: 25px;
	color: #000000;
	letter-spacing: 0.36px;
	margin: 0;
	@media screen and (max-width: 767px){
		font-size: 20px;
	}
}
h4{
	font-size: 16px;
	color: #fff;
	letter-spacing: 0;
	text-align: center;
	line-height: 22px;
	margin: 0;
}
.fa{
  font-family: "Font Awesome 5 brands" !important;
}
.owl-item {
	position:relative;
}
.slide-content, .group-container-header{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateX(8%) translateY(-50%);
    background: #fff;
    padding: 50px;
    width: 30%;
    z-index: 9999;
    @media screen and (max-width: 767px){
    	position: relative;
	    top: auto;
	    bottom: 15px;
	    left: 0;
	    transform: unset;
	    background: #fff;
	    padding: 10px 15px;
	    width: 95%;
	    max-width: 550px;
	    margin: 0;
    }
    @media (min-width: 768px) and (max-width: 1200px){
	    transform: unset;
	    padding: 25px 50px;
	    width: 80%;
	    left: 40px;
	    top: auto;
	    bottom: 20px;
	    position: relative;
	    float: left;
	    max-width: 1024px;
	}
	/*@media (min-width: 1201px) and (max-width: 1399px){
	    transform: translateX(-70%) translateY(-50%);
	    padding: 40px 20px;
	    max-width: 315px;
	}
	@media (min-width: 1400px) and (max-width: 1600px){
		transform: translateX(-65%) translateY(-50%);
	    padding: 50px;
	    max-width:375px;
	}*/
    &:after {
	  	content: '';
	    position: absolute;
	    width: 150px;
	    height: 10px;
	    background: #3A3A3C;
	    top: 50%;
	    right: -80px;
	    transform: translateY(-50%);
	    display: block;
	    @media screen and (max-width: 767px){
	    	display: none;
	    }
    }
}
#block-views-home-slider-block-home-slider .view-id-home_slider .slide-content {
	h1 {
		font-size: 30px;
		max-width: 300px;
		@media (min-width: 768px) and (max-width: 1200px){
			max-width: 100%;
		}
		&:after{
			content: "&";
		    font-size: 4em;
		    font-weight: 900;
		    color: #3A3A3C;
		    position: absolute;
		    left: 5px;
		    top: 30px;
		    z-index: -1;
		    letter-spacing: 2.86px;
			line-height: 53px;
			opacity: 0.07;
		}
		@media screen and (max-width: 767px){
			max-width: 100%;
		}
	}
	p {
		color: #7B7B7B;
		letter-spacing: 0.2px;
		font-weight: 300;
	}
	.field-name-field-r-seaux-sociaux {
		.field-item{
			padding: 0 5px;
			margin-top: 20px;
			&.even{
				display: inline-block;
				font-size: 0;
				a:before{
					content:"\f167";
					font-size: 20px;
					color: #000;
					font-family:"Font Awesome 5 brands";
				}
				&:nth-child(1) a:before{
					content:"\f39e";
					font-size: 20px;
					color: #000;
					font-family:"Font Awesome 5 brands";
				}
				 
			}
			&.odd {
				display: inline-block;
				font-size: 0;
				a:before {
					content:"\f16d";
					font-size: 20px;
					color: #000;
					font-family:"Font Awesome 5 brands";
				}
			}
		}
	}
}
#block-block-2 {
	border-left: 8px solid #3a3a3c;
    padding: 15px 0 15px 20px;
    @media screen and (max-width: 767px){
    	border-left: unset;
	    padding: 0;
	    text-align: center;
	    margin: 0 0 30px;
    }
    @media (min-width: 768px) and (max-width: 991px){
    	padding: 15px 10px;
    }
	.bloc-sociaux {
		ul {
			margin:0;
			padding: 0;
			li {
				text-decoration: none;
			    list-style-type: none;
			    display: inline-block;
			    padding:0 5px;
			}
		}
	}
	.bloc-coordonnés {
	    margin: 25px 0 10px;
	}
	i {
		vertical-align: middle;
	}
}
footer {
	border-top: none!important;
	background-color: #E5E5E5;
	font-size: 14px;
	color: #151515;
	letter-spacing: 0.2px;
	.block-menu{
		ul{
			margin-top: 10px;
			@media screen and (max-width: 767px){
				margin-top: 0;
			}
		}
	}
}
#block-menu-menu-menu-footer, #block-menu-menu-menu-secondaire-footer{
	@media screen and (max-width: 767px){
		margin-bottom: 30px;
	}
	ul li a {
		padding:5px 0;
		text-align: center;
		opacity: 1;
		color: #151515;
		&:hover {
			font-weight: bold;
			background-color: transparent;
		}
	}
}
.fa-facebook-f:before, .fa-instagram:before, .fa-youtube:before, .fa-pinterest:before{
    font-size: 20px;
    color: #151515;
}
.fa-instagram, .fa-youtube, .fa-pinterest{
	padding-top:5px;
}
#block-views-projets-projets-home {
	.group-project-first-section.field-group-div{
		&:after {
		    content: '';
		    background: white;
		    position: absolute;
		    top: -1px;
		    left: 0;
		    right: 0;
		    height: 26px;
		    display: block;
		    @media screen and (max-width: 767px){
		    	display: none;
		    }
		}
	}    
	.field-name-field-titre-icone {
		margin-top: 30px;
	}
	.bloc-icone{
		max-width: 240px;
		margin: auto;
		.field-name-field-nb-etages, .field-name-field-nb-appartements{
			width: 50%;
			font-weight: 500;
			text-align: center;
			display: inline-block;
			margin-top: 10px;
			@media screen and (max-width: 767px){
				width: 100%;
				text-align: left;
				padding: 5px 0;
				display: flex;
			}
			.even{
				padding:0;
				@media screen and (max-width: 767px){
					padding:8px 0;
				}
			}
		}
		.field-name-field-jardin-privatif,.field-name-field-standing, .field-name-field-terrasses,
		.field-name-field-parking-soussol,.field-name-field-suite-parental,
		.field-name-field-parking-exterieur, .field-name-field-ascenseur{
			display: none;
		}
	}
	.field-name-field-standing:before{
		color: #fff;
	}	
}
#block-system-main{
	.header-section{
		.header-image{
			.group-container-header{
			    h1{
			    	&:after{
			    		content: "R";
					    font-size: 4em;
					    font-weight: 900;
					    color: #3A3A3C;
					    position: absolute;
					    left: 5px;
					    top: 30px;
					    z-index: -1;
					    letter-spacing: 2.86px;
					    line-height: 53px;
					    opacity: 0.07;
			    	}
			    }
			    .field-name-field-ville {
			    	.even{
			    		font-size: 16px;
			    		color: #7B7B7B;
			    		font-weight: 600;
			    		&:before {
			    			content: "\e916";
			    			font-size: 16px;
						    font-family: 'icomoon' !important;
						    font-weight: normal;
						    margin-right: 5px;
			    		}
			    	}
			    }
				.field-name-partage-{
					display: flex;
					position: absolute;
    				right: 30px;
    				.field-label{
    					font-weight: 300;
    					padding:3px 4px;
    				}
					ul{
						padding:0;
						li{
							display: inline-block;
							padding:0 3px;
							i.fa-facebook-f:before, .fa-instagram:before,
							 .fa-twitter:before, .fa-pinterest:before {
								font-size: 15px;
							}
						}
					}
				}
			}
		}
	}
	.section-information-project{
		.group-container-highlight{
			text-align: center;
			margin-bottom: 30px;
			.field-name-field-nb-etages, .field-name-field-nb-appartements,
			.field-name-field-jardin-privatif, .field-name-field-standing, 
			.field-name-field-terrasses, .field-name-field-parking-soussol, 
			.field-name-field-ascenseur, .field-name-field-suite-parental,
			.field-name-field-parking-exterieur{
				display: inline-block;
			    padding: 0 5%;
			    font-size: 14px;
			    color: #4A4A4A;
			    letter-spacing: 0.2px;
			    text-align: center;
			    line-height: 14px;
			    font-weight: 600;
    			margin-top: 30px;
    			@media screen and (max-width: 767px){
    				width: 50%;
    				padding:0 6px;
    			}
    			.even {
    				padding:15px 0 0;
    			}
			}
			.field-name-go-appartements {
				margin: 50px 0 100px;
				.go {
					background-color:#000;
					font-size: 14px;
					color: #FFFFFF;
					letter-spacing: 0.2px;
					padding: 20px 30px;
					text-transform: uppercase;
					font-weight: 600;
					@media screen and (max-width: 767px){
						padding:20px;
					}
					&:before{
						content: '\e908';
					    font-family: 'icomoon' !important;
					    margin-right: 10px;
					    font-size: 25px;
					    font-weight: 200;
					}
				}
			}
		}
		.field-name-field-map-code{
			width: 100%;
		    max-width: 810px;
		    float: right;
		    margin: 44px 0 95px;
		    @media screen and (max-width: 767px){
		    	max-width: 100%;
		    }
		    @media (min-width: 768px) and (max-width: 991px){
		    	max-width: 600px;
		    }
		    iframe{
		    	width: 100%;
		    	height: 300px;
		    }
		}
	}
	.field-name-field-description,.field-name-field-avantage, 
	.field-name-field-description-emplacement {
		.field-label{
			font-size: 22px;
			color: #4A4A4A;
			letter-spacing: 2.37px;
			line-height: 61px;
			text-transform: uppercase;
			position:relative;
			/*@media screen and (max-width: 767px){
				margin-top: 80px;
			}*/
			&:after{
				content:"";
				position:absolute;
				bottom: 10px;
				left: 0; 
				border-bottom:4px solid #4A4A4A;
				width: 50px;
			}
		}
		.field-items{
			font-size: 14px;
			color: #4A4A4A;
			letter-spacing: 0.2px;
		}
	}
	.field-name-field-description{
		width: 100%;
	    position: relative;
	    padding-left: 19%;
	    @media screen and (max-width: 767px){
	    	padding-left:0;
	    	padding-top: 70px;
	    }
		&:before{
			content: " ";
		    background: url(http://lesfables.staging.spicy.digital/sites/default/files/images/a-prorpos.png)no-repeat top left;
		    width: 100%;
		    height: 100%;
		    position: absolute;
		    left: 0;
		    top: 18px;
		    @media screen and (max-width: 767px){
		    	background: url(http://lesfables.staging.spicy.digital/sites/default/files/images/a-prorpos.png)no-repeat top center;
		    	left: auto;
		    	top: 0;
		    	background-size: 25%;
		    }
		}
	}
	.field-name-field-avantage{
		width: 100%;
	    position: relative;
	    padding-right: 130px;
	    margin-top: 25px;
	    @media screen and (max-width: 767px){
	    	padding-right:0;
	    	padding-top: 70px;
	    }
		&:after{
			content: " ";
		    background: url(http://lesfables.staging.spicy.digital/sites/default/files/images/avantage.png) no-repeat top right;
		    width: 100%;
		    height: 100%;
		    position: absolute;
		    top: 25px;
		    @media screen and (max-width: 767px){
		    	background: url(http://lesfables.staging.spicy.digital/sites/default/files/images/avantage.png)no-repeat top center;
		    	top: 0;
		    	background-size: 25%;
		    }
		}
		ul{
			padding:0;
			li{
				list-style-type: none;
				&:before {
					content: '✓';
				    padding-right: 20px;
    				font-size: 16px;
				}
			}	
		}
	}
	.field-name-field-description-emplacement{
		width: 100%;
	    position: relative;
	    padding-left: 19%;
	    margin-top: 25px;
	    @media screen and (max-width: 767px){
	    	padding-left:0;
	    	padding-top: 70px;
	    }
		&:before{
			content: " ";
		    background: url(http://lesfables.staging.spicy.digital/sites/default/files/images/emplacement.png)no-repeat top left;
		    width: 100%;
		    height: 100%;
		    position: absolute;
		    left: 0;
		    top: 23px;
		    @media screen and (max-width: 767px){
		    	background: url(http://lesfables.staging.spicy.digital/sites/default/files/images/emplacement.png)no-repeat top center;
		    	top: 0;
			    left: auto;
			    background-size: 25%;
		    }
		}
	}
}
.field-name-field-nb-etages:before{
	content: '\e911';
	font-size: 25px;
	color: #000;
	font-family: 'icomoon';
	display: block;
	font-weight: 100;
	@media screen and (max-width: 767px){
		display: inline-block;
		vertical-align: middle;
		padding:0 15px;
	}
}
.field-name-field-nb-appartements:before{
	content: '\e900';
	font-size: 25px;
	color: #000;
	font-family: 'icomoon';
	display: block;
	font-weight: 100;
	@media screen and (max-width: 767px){
		display: inline-block;
		vertical-align: middle;
		padding:0 15px;
	}
}
.field-name-field-jardin-privatif:before{
	content: '\e915';
	font-size: 25px;
	color: #000;
	font-family: 'icomoon';
	display: block;
	font-weight: 100;
	@media screen and (max-width: 767px){
		display: inline-block;
		vertical-align: middle;
		padding:0 15px;
	}
}
.field-name-field-standing:before{
	content: '\e914';
	font-size: 25px;
	color: #000;
	font-family: 'icomoon';
	display: block;
	font-weight: 100;
	@media screen and (max-width: 767px){
		display: inline-block;
		vertical-align: middle;
		padding:0 15px;
	}
}
.field-name-field-terrasses:before{
	content: '\e919';
	font-size: 25px;
	color: #000;
	font-family: 'icomoon';
	display: block;
	font-weight: 100;
	@media screen and (max-width: 767px){
		display: inline-block;
		vertical-align: middle;
		padding:0 15px;
	}
}
.field-name-field-parking-soussol:before{
	content: '\e918';
	font-size: 25px;
	color: #000;
	font-family: 'icomoon';
	display: block;
	font-weight: 100;
	@media screen and (max-width: 767px){
		display: inline-block;
		vertical-align: middle;
		padding:0 15px;
	}
}
.field-name-field-suite-parental:before{
	content: '\e903';
	font-size: 25px;
	color: #fff;
	font-family: 'icomoon';
	display: block;
	font-weight: 100;
	@media screen and (max-width: 767px){
		display: inline-block;
		vertical-align: middle;
		padding:0 15px;
	}
}
.field-name-field-parking-exterieur:before{
	content: '\e902';
	font-size: 25px;
	color: #000;
	font-family: 'icomoon';
	display: block;
	font-weight: 100;
	@media screen and (max-width: 767px){
		display: inline-block;
		vertical-align: middle;
		padding:0 15px;
	}
}
.field-name-field-ascenseur:before{
	content: '\e901';
	font-size: 25px;
	color: #000;
	font-family: 'icomoon';
	display: block;
	font-weight: 100;
	@media screen and (max-width: 767px){
		display: inline-block;
		vertical-align: middle;
		padding:0 15px;
	}
}

#block-views-f01654fd181d38ab562384bba3cb63a0{
	.view-id-appartements_residence{
		display: block;
		@media screen and (max-width: 767px){
			max-width: 350px;
			margin: auto;
		}
		.view-filters{
			width: 25%;
			float: left;
			border: 1px solid #80808070;
		    padding: 20px;
		    min-height: 385px;
		    position: relative;
		    @media screen and (max-width: 767px){
		    	margin-bottom: 20px;
		    	width: 100%;
		    }
		    .views-reset-button{
				position: absolute;
			    bottom: 0;
			    padding:0 0 20px;
			    .form-submit{
				    border: none;
				    background: transparent;
				    text-decoration: underline;
				    font-size: 12px;
				    color: #D40000;
				    letter-spacing: 0.17px;
				    margin:0;
				    font-family: 'Avenir';
				    &:hover{
				    	text-decoration: none;
				    }
				}
			}
			.views-exposed-form {
				label:nth-child(1){
				    font-size: 14px;
				    color: #000000;
				    letter-spacing: 0.2px;
				    font-weight: 600;
				    margin-bottom: 30px;
				}
				.views-widget{
					.form-control{
						display: block;
					    width: 60px;
					    height: 15px;
					    padding: 0;
					    font-size: 12px;
					    line-height: 1.42857143;
					    color: #000;
					    background-color: transparent;
					    background-image: none;
					    border: none;
					    border-radius: 0;
					    box-shadow: unset;
					}
				}
			}
			.form-item-edit-field-pieces-value-1, .form-item-edit-field-pieces-value-2, .form-item-edit-field-pieces-value-3,
			.form-item-edit-field-pieces-value-4, .form-item-edit-field-pieces-value-5, .form-item-edit-field-pieces-value-6{
				display: flex;
				label {
				    display: inline-block;
				    max-width: 100%;
				    margin-bottom: 20px;
				    padding-top: 4px;
				}
			}
		}
		.view-content{
			width: 77%;
			float: left;
			@media screen and (max-width: 767px){
				width: 100%;
			}
			@media (min-width: 768px) and (max-width: 991px){
				width: 65%;
			}
			.col-appartement{
				.field-name-field--etage, .field-name-field-superficie, .field-name-field-terrasse,
				.field-name-field-dressing, .field-name-field-jardin, .field-name-field-orientation{
					display: flex;
					@media screen and (max-width: 767px){
						display: block;
						text-align: center;
					}
					.field-label{
						text-transform: uppercase;
						font-weight: 600;
						padding:5px 0;
					}
					.field-items{
						padding:5px 0;
					}
				}
			}
		}
	}
}
.qui-sommes-nous{
	.node-titre-description{
		margin: 62px 0 0;
		.group-content{
			text-align: center;
			max-width: 695px;
			.field{
				h2{
					text-transform: uppercase;
					margin-bottom: 25px;
					font-weight: 900;
    				font-style: normal;
				}
				p{
					font-size: 22px;
					font-weight: 600;
    				font-style: normal;
				    color: #7B7B7B;
				    letter-spacing: 0.2px;
				}
			}
		}
	}
	.node-description-background-black {
	    margin: 74px 0 67px;
	}
	.node-equipe{
		.field{
			p{
				color: #A7A7A7;
				letter-spacing: 0.2px;
				margin: 0;
			}
		}
		.paragraphs-items-field-element-carousel{
			.field-name-field-element-carousel{
				max-width: 680px;
    			margin: auto;
    			@media screen and (max-width: 767px){
    				max-width: 100%;
    				text-align: center;
    			}
    			.field-item{
    				display: inline-block;
    				padding:0 10px;
    				.paragraphs-item-carousel-equipe{
    					position: relative;
    					.img-responsive{
    						max-height: 430px;
    						object-fit: cover;
    					}
    					.group-nouveau-membre{
    						background: #3a3a3c;
						    padding: 15px;
						    position: absolute;
						    bottom: 20px;
						    right: -10px;
    						.field-name-field-nom-prenom{
								font-size: 16px;
								color: #FFFFFF;
								letter-spacing: 0.23px;
								text-align: left;
								font-weight:700;
							}
							.field-name-field-fonction{
								font-size: 16px;
								color: #FFFFFF;
								letter-spacing: 1.33px;
								text-align: left;
								font-weight: 300;
							}
    					}
    				}
    			}
			}
		}
	}
}
footer {
	position:relative;
	#block-block-7{
		p, a{
			font-size: 12px;
			color: grey;	
		}
		a{
			text-decoration: underline;
			&:hover{
				text-decoration: none;
			}
		}
	    position: absolute;
	    left: 0;
	    bottom: 5px;
	    padding: 0;
	    @media screen and (max-width: 767px){
	    	text-align: center;
	    }
	}
}
.field-name-field-r-seaux-sociaux {
    display: none;
    @media screen and (max-width: 767px){
    	display: block;
    }
}
#youtube-field-player {
   /* position: relative;
    z-index: 0;
    width: 100% !important;*/
    /*@media screen and (max-width: 767px){
    	height: 300px;
    }
    @media (min-width: 768px) and (max-width: 1200px){
    	height: 500px;
    }*/
}
.block-title{
	font-size: 22px;
    color: #4A4A4A;
    letter-spacing: 2.37px;
    line-height: 61px;
    text-transform: uppercase;
    position:relative;
    &:after{
    	content: "";
	    position: absolute;
	    bottom: 10px;
	    left: 0;
	    border-bottom: 4px solid #4A4A4A;
	    width: 50px;
    }
}
#block-views-appartements-residence-block-1{
	.view-id-appartements_residence{
		display: block;
		.view-filters{
			width: 25%;
			float: left;
			border: 1px solid #80808070;
		    padding: 20px;
		    min-height: 385px;
		    position: relative;
		    @media screen and (max-width: 767px){
		    	margin-bottom: 20px;
		    	width: 100%;
		    }
		    .views-reset-button{
				position: absolute;
			    bottom: 0;
			    padding:0 0 20px;
			    .form-submit{
				    border: none;
				    background: transparent;
				    text-decoration: underline;
				    font-size: 12px;
				    color: #D40000;
				    letter-spacing: 0.17px;
				    margin:0;
				    font-family: 'Avenir';
				    &:hover{
				    	text-decoration: none;
				    }
				}
			}
			.views-exposed-form {
				label:nth-child(1){
				    font-size: 14px;
				    color: #000000;
				    letter-spacing: 0.2px;
				    font-weight: 600;
				    margin-bottom: 30px;
				}
				.views-widget{
					.form-control{
						display: block;
					    width: 60px;
					    height: 15px;
					    padding: 0;
					    font-size: 12px;
					    line-height: 1.42857143;
					    color: #000;
					    background-color: transparent;
					    background-image: none;
					    border: none;
					    border-radius: 0;
					    box-shadow: unset;
					}
				}
			}
			.form-item-edit-field-pieces-value-1, .form-item-edit-field-pieces-value-2, .form-item-edit-field-pieces-value-3,
			.form-item-edit-field-pieces-value-4, .form-item-edit-field-pieces-value-5, .form-item-edit-field-pieces-value-6{
				display: flex;
				label {
				    display: inline-block;
				    max-width: 100%;
				    margin-bottom: 20px;
				    padding-top: 4px;
				}
			}
		}
		.view-content{
			width: 73%;
			float: left;
			@media screen and (max-width: 767px){
				width: 100%;
			}
			@media (min-width: 768px) and (max-width: 991px){
				width: 55%;
			}
			.col-appartement{
				.field-name-field--etage, .field-name-field-superficie, .field-name-field-terrasse,
				.field-name-field-dressing, .field-name-field-jardin, .field-name-field-orientation{
					display: flex;
					@media screen and (max-width: 767px){
						display: block;
						text-align: center;
					}
					.field-label{
						text-transform: uppercase;
						font-weight: 600;
						padding:5px 0;
					}
					.field-items{
						padding:5px 0;
					}
				}
			}
		}
	}
}
#block-views-appartements-residence-block-1 .view-id-appartements_residence .view-filters{
	width: 20%;
    margin-right: 15px;
    @media screen and (max-width: 767px){
    	width: 100%;
    	margin-right: 0;
    }
    @media (min-width: 768px) and (max-width: 991px){
		width: 40%;
	}
}
#block-views-appartements-residence-block-1{
	.view-appartements-residence{
		.text-center{
			.pagination{
				margin: 0;
				li{
					font-size: 16px;
				    color: #000000;
				    letter-spacing: 0.23px;
					span, a{
						margin: 0 10px;
						border-radius:0;
						color: #000;
						border:3px solid transparent;
						background-color:transparent;
						&:hover{
							background-color:transparent;
							border: 3px solid #000;
						}
					}
				}
				.next{
					a{
						border:3px solid transparent;
					    font-size: 0;
					    margin: 0;
					    padding: 0;
					    &:hover{
					    	color: #000;
					    	border:3px solid transparent;
					    }
    					&:before{
    						content: '\e905';
						    font-family: 'icomoon';
						    transform: rotate(270deg);
						    font-size: 10px;
						    position: absolute;
						    top: 10px;
						    left: 0;
    					}
					}
				}
				.prev{
					a{
						border:3px solid transparent;
					    font-size: 0;
					    margin: 0;
					    padding: 0;
					    &:hover{
					    	color: #000;
					    	border:3px solid transparent;
					    }
    					&:before{
    						content: '\e905';
						    font-family: 'icomoon';
						    transform: rotate(90deg);
						    font-size: 10px;
						    position: absolute;
						    top: 10px;
						    left: auto;
						    right: 0;
    					}
					}
				}
				.pager-last, .pager-first{
					display: none;
				}
				.active{
					span{
						background-color:transparent;
						border: 3px solid #000;
					}
				}
			}
		}
	}
}
.page-search{
	.col-appartement{
		margin-bottom: 30px;
		transition: all .3s ease-out;
		-moz-transition: all .3s ease-out;
	    -o-transition: all .3s ease-out;
	    -webkit-transition: all .3s ease-out;
		&:hover{
			-webkit-box-shadow: 0px 2px 5px 0px rgba(113,113,113,0.6);
			-moz-box-shadow: 0px 2px 5px 0px rgba(113,113,113,0.6);
			box-shadow: 0px 2px 5px 0px rgba(113,113,113,0.6);
		}
		.app-image{
			position: relative;
			&:hover{
				.app-price{
					max-height: 100%;
				}
			}
			.field-type-image{
				.content{
					img{
						height: 270px;
						object-fit: contain;
					}
				}
			}
			.app-price{
				position: absolute;
			    bottom: 0;
			    width: 100%;
			    text-align: center;		    
			    color: #fff;
			    background-color: #00000070;
			    max-height: 0;
				overflow: hidden;
				/* Set our transitions up. */
				-webkit-transition: max-height 0.8s;
				-moz-transition: max-height 0.8s;
				transition: max-height 0.8s;
				.field-name-field-prix{
					padding: 20px;
					margin: 0;
					&.field-label-inline{
						.field-label,.field-items{
							float: none;
							font-size: 18px;
						    font-weight: 600;
						    display: inline-block;
						}
					}
				}
			}
		}
		.app-content{
			padding: 20px;
			min-height: 280px;
			@media screen and (max-width: 767px){
				text-align: center;
			}
			.app-title{
				margin: 0;
				margin-bottom: 10px;
			}
		}
	}	
}
.page-search{
	.node-appartement {
	    margin: 0 0 82px;
	}
	.node-projets{
		.header-section{
			.group-container-header{
				padding:80px 50px;
				@media screen and(max-width: 767px){
					padding:10px 15px;
				}
				.field-name-title{
					h1{
						max-width: 260px;
					}
				}
			}
		}
	}
	.field-name-field-terrasses:before{
		content: '\e919';
		font-size: 25px;
		color: #000;
		font-family: 'icomoon';
		display: block;
		font-weight: 100;
		@media screen and (max-width: 767px){
			display: inline-block;
			vertical-align: middle;
			padding:0 15px;
		}
	}
	.field-name-field-suite-parental:before{
		content: '\e903';
		font-size: 25px;
		color: #000;
		font-family: 'icomoon';
		display: block;
		font-weight: 100;
		@media screen and (max-width: 767px){
			display: inline-block;
			vertical-align: middle;
			padding:0 15px;
		}
	}
}
.view-recherche {
	.view-id-appartements_residence{
		.view-filters {
		    width: 25%;
		    float: left;
		    border: 1px solid #80808070;
		    padding: 20px;
		    min-height: 385px;
		    position: relative;
		}
	}
}
 
#block-views-appartements-residence-block-1{
	margin-top: 100px;
	margin-bottom: 100px;
	.view-appartements-residence{
		margin: 50px 0 0;
		.field-name-field-nom-residence{
			font-size: 18px;
			font-style: italic;
			text-transform: capitalize;
			margin: 8px 0;
		}
	}
}
.node-appartement, .views-bootstrap-grid-plugin-style{
	.col-appartement{
		.app-image{
			.content,
			a{
				&:before{
				    content: '';
				    background: url(../images/agrandir.png) no-repeat;
				    position: absolute;
				    top: 10px;
				    right: 0;
				    width: 18px;
				    height: 18px;
				    cursor: pointer;
				    background-size: 90%;
				}
				img{
				    margin: auto;
				    padding:0;
				}
			}
			.content{
				&:before{
					right: 10px;
					z-index: -1;
				}
			}
		}
	}
}
.fixed {
  position: fixed;
  top:0; 
  left:0;
  width: 100%; 
}
.alert-success {
    background-color: #3a3a3c;
    border-color: #000;
    color: #fff;
    max-width: 300px;
    margin: auto;
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 50px;
    z-index: 99999;
    text-align: center;
    .close {
	    float: right;
	    font-size: 20px;
	    font-weight: bold;
	    line-height: 1;
	    color: #000;
	    -webkit-opacity: 1;
	    -moz-opacity: 1;
	    opacity: 1;
	    text-shadow: unset;
	}
	p{
		text-transform: uppercase;
		line-height: 30px;
    	letter-spacing: 3px;
	}
}
.alert-dismissable .close, .alert-dismissible .close {
    position: absolute;
    top: 5px;
    right: 10px;
    color: #fff;
    background: transparent;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    padding: 3px 7px;
}
.alert-success a:hover, .alert-success a:focus {
    background: white;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    padding: 3px 7px;
    color: black;
    transition: 0.5s ease-in-out;
}
.view-gallerie{
	margin-top: 60px;
	h1{
		text-align: center;
	}
	h2{
		display: none;
	}
	.view-vue-gallerie-projet{
		margin-top: 40px;
		.view-content{
			.owl-item{
				img{
					margin: auto;
				}
			}
		}
	}
}
.view-vue-bloc-vid-o{
	margin-top: 80px;
	margin-bottom: 50px;
	header, footer{
		display: none;
	}
}
.view-vue-galerie-residence{
	header, footer{
		display: none;
	}
	.view-header{
		max-width: 105px;
	    margin: auto;
	    margin-bottom: 34px;
    	p{
    		font-size: 22px;
    		font-weight: bold;
		    color: #4A4A4A;
		    letter-spacing: 2.37px;
		    line-height: 61px;
		    text-transform: uppercase;
		    position: relative;
		    &:after{
		    	content: "";
			    position: absolute;
			    bottom: 10px;
			    left: 0;
			    border-bottom: 4px solid #4A4A4A;
			    width: 50px;
		    }
    	}
	}
}
.view-vue-blog, .view-vue-bloc-blog{
	.view-content{
		margin-top: 80px;
		@media screen and (max-width: 767px){
			margin-top: 40px;
		}
		.node-teaser{
			display: inline;
			.container{
				border-bottom: 1px solid #000;
			    padding: 0;
			    padding-bottom: 40px;
			    @media screen and (max-width: 767px){
			    	padding: 0 15px;
			    	padding-bottom: 63px;
			    }
				.bloc-image{
					max-width: 460px;
					width: 100%;
					float: left;
					@media (min-width: 768px) and (max-width: 991px){
						max-width: 100%;
					}
					img{
						height: 260px;
						@media screen and (max-width: 991px){
							height: auto;
						}
					}
				}
				.bloc-description{
					width: 650px;
				    float: left;
				    position: absolute;
				    top: 40%;
				    transform: translateY(-50%);
				    left: 45%;
				    @media (min-width: 768px) and (max-width: 991px){
				    	width: 100%;
					    float: left;
					    position: relative;
					    top: 0;
					    transform: none;
					    left: 0;
					    padding: 10px;
				    }
				    @media (min-width: 992px) and (max-width: 1199px){
				    	width: 460px;
				    	left:50%;
				    }
				    @media screen and (max-width: 767px){
				    	width: 100%;
				    	position: relative;
					    top: 10px;
					    transform: unset;
					    left: 0;
				    }
					.field-name-field-date-de-publication{
						span{
							font-weight: bold;
	    					text-transform: uppercase;
						}
					}
					.field-name-title{
						h2{
							font-size: 22px;
							text-transform: uppercase;
							line-height: 35px;
						}
					}
					.field-name-field-contenu{
						h2{
							font-size: 14px;
							font-weight: 500;
							line-height: normal;
							text-transform: initial;
							letter-spacing: 0.2px;
						}
					}
					.field-name-node-link{
						margin-top: 30px;
						a{
							color: #3a3a3c;
						    border: 2px solid #3a3a3c;
						    padding: 7px 45px 6px 10px;
						    text-transform: uppercase;
						    font-weight: bold;
						    font-size: 12px;
						    position: relative;
						    top: 10px;
						    background: transparent;
						    transition: .2s ease-in-out;
						    &:before {
							    content: '\e905';
							    position: absolute;
							    right: 5px;
							    top: 10px;
							    font-family: 'icomoon';
							    transform: rotate(270deg);
							    font-size: 8px;
							}
							&:hover{
								background: #3a3a3c;
								color: #fff;
								&:before{
									color: #fff;
								}
							}
						}
					}
				}
			}
		}
		.views-row{
			margin-bottom: 40px;
		}
	}
}
.view-vue-bloc-blog{
	margin-top: 80px;
	.view-header{
		text-align: center;
	}
	.view-content {
	    margin-top: 30px;
	    .views-row{
	    	.node-article{
	    		.container{
	    			border-bottom: unset;
	    			padding-bottom: 0;
	    			.bloc-description{
	    				width: 50%;
	    				top: 50%;
	    				@media screen and (max-width: 767px){
	    					width: 100%;
	    				}
	    				.field-name-field-contenu{
	    					margin-top: 0;
	    				}
	    			}
	    		}
	    	}
	    }
	}
} 
.view-vue-header-blog{
	.node-header-blog{
		.field-name-field-image{
			img{
			    width: 68%;
			    float: right;
			    max-height: calc(100vh - 25vh);
			    @media (min-width: 768px) and (max-width: 1200px){
			    	width: 100%;
			    	height: auto;
			    }
			    @media screen and (max-width: 767px){
			    	width: 100%;
			    	float: inherit;
			    }
			}
		}
		.container-header{
			position: absolute;
		    top: 50%;
		    left: 10px;
		    transform: translateX(8%) translateY(-50%);
		    background: #fff;
		    padding: 50px;
		    width: 30%;
		    z-index: 9999;
		    &:after{
		    	content: '';
			    position: absolute;
			    width: 150px;
			    height: 10px;
			    background: #3A3A3C;
			    top: 50%;
			    right: -80px;
			    transform: translateY(-50%);
			    display: block;
		    }
			@media (min-width: 768px) and (max-width: 1200px){
				transform: unset;
			    padding: 25px 50px;
			    width: 65%;
    			left: 80px;
			    top: auto;
			    bottom: 20px;
			    position: relative;
			    float: left;
			    max-width: 1024px;
			}
			@media screen and (max-width: 767px){
				position: relative;
			    top: auto;
			    bottom: 15px;
			    left: 10px;
			    transform: unset;
			    background: #fff;
			    padding: 10px;
			    width: 95%;
			    max-width: 550px;
			    margin: 0;
			    &:after{
			    	display: none;
			    }
			}
			.field-name-title{
				h2{
					font-size: 30px;
				    margin: 0;
				    font-weight: 900;
				    color: #3A3A3C;
				    letter-spacing: 0.71px;
				    line-height: 53px;
				    text-transform: uppercase;
				}
			}
		}
		.field-name-scroll{
			.back-down{
				position: absolute;
			    bottom: 15%;
			    left: 5%;
			    display: block;
			    @media screen and (max-width: 991px){
			    	position: relative !important;
			    }
			    @media (min-width: 768px) and (max-width: 1200px){
			    	position: absolute !important;
				    bottom: 40px;
				    left: 35px;
			    }
			    @media screen and (max-width: 767px){
			    	display: none;
			    }
			    a{
			    	color: #151515;
				    letter-spacing: 0.2px;
				    font-weight: 900;
				    position: relative;
				    &:before{
				    	content: '\e905';
					    color: #000;
					    position: absolute;
					    bottom: -35px;
					    right: -10px;
					    font-family: 'icomoon' !important;
				    }
			    }
			}
		}
	}
}
.node-article{
	.field-name-field-image{
		/*img{
			width: 100%;
		    height: calc(100vh - 225px);
		    object-fit: cover;
		    @media (min-width: 768px) and (max-width: 991px){
		    	height: calc(100vh - 260px);
		    }
		    @media screen and (max-width: 767px){
		    	height: auto;
		    	object-fit: unset;
		    }
		}*/
	}
	.container{
		.first-bloc{
			position: relative;
		    display: flex;
		    margin: 20px 0;
		    .field-name-field-date-de-publication{
		    	margin-top: 11px;
		    	span{
		    		font-weight: bold;
    				text-transform: uppercase;
		    	}
		    }
		    .field-name-partage-{
		    	position: absolute;
		    	right: 0;
		    	ul{
		    		padding: 0;
		    		display: flex;
		    		li{
		    			list-style-type: none;
		    			padding-top: 5px;
    					padding-right: 10px;
    					&:nth-child(2){
    						padding: 0;
    					}
		    		}
		    	}
		    }
		}
		.field-name-field-contenu{
			margin-top: 16px;
		}
		.paragraphs-items-field-detail-article{
			margin-top: 40px;
			.field-item{
				margin-top: 30px;
				.paragraphs-item-image-et-contenu, .paragraphs-item-contenu-et-image{
					.field-name-field-image{
						.field-item{
							margin-top: 0;
						}
						img{
							height: 300px;
						}
					}
					.field-name-field-description-article{
						.field-item{
							margin-top: 0;
						}
					}
				}
				.paragraphs-item-video-blog{
					.content{
						.field-name-field-video-detail-blog{
							iframe{
								margin-left: 0 !important;
							}
						}
					}
				}
			}
		}
	}
}