@media screen and (max-width: 767px){
	.carousel-black {
	    width: 100%;
	}
	.text-right{
		text-align: center;
	}
	.navbar .logo{
		padding: 0;
		img {
			max-width: 180px;
		}
	}
	#block-views-f01654fd181d38ab562384bba3cb63a0{
		.col-appartement {
			.field-name-title{
				text-align: center;
			}
			.app-link{
				text-align: center;
			}
		}	
	}
	#block-block-4 {
	    display: none;
	}
	.page-node-13{
		.node-header-basic{
			.scroll-content{
				clear: both;
				.group-container-header{
					top: auto;
			    	transform: unset;
			    	left: 0;
					padding: 10px;
				}
			}
		}
	}
	.page-node-19{
		.node-header-basic{
			.group-container-header{
				transform: unset;
			    padding: 25px;
			    width: 100%;
			    left: -15px;
			    top: auto;
			    bottom: 20px;
			    position: relative;
			    float: left;
			    max-width: 100%;
			}
		}
	}
	.page-node-13 .node-header-basic .field-type-image img, 
	.page-node-19 .node-header-basic .field-name-field-map-code iframe,
	.header-section .header-image img{
		width: 100%;
	}
	.header-section .header-image{
		width: 100%;
		float: none;
	}
	.page-node-4{
		.node-promoted{
			.field-name-field-description{
				padding-top:70px;
			}
		}
	}
	.node-appartement {
	    margin: 0 0 5px;
	}
	.owl-carousel-project-home{
		.owl-pagination{
			margin-top: 50px;
		}
	}
}
@media screen and (max-width: 991px){
	.back-down{
		position:relative !important;
	}
	.navbar-toggle {
	    display: block;
	    position: absolute;
	    right: 10px;
	    float: none;
	    margin-right: 15px;
	    padding: 9px 10px;
	    margin-top: 8px;
	    margin-bottom: 8px;
	    background-color: transparent;
	    background-image: none;
	    border: 1px solid transparent;
	    border-radius: 4px;
	}
	.navbar-default .navbar-toggle {
	    border-color: transparent;
	    margin-right: 0;
	    padding:5px 10px;
	}
	.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
	    background-color: transparent;
	}
	.navbar-default .navbar-collapse, .navbar-default .navbar-form {
	    border-color: transparent;
	}
	header.navbar-default{
		.navbar-collapse {
			/*display: none;*/
			visibility: hidden;
			transition: 0.7s ease-in-out;
			nav {
				width: 0;
				margin: 0;
				.region-navigation {
					display: none;
				}
			}
			&.in{
				/*display: block;*/
				visibility: visible;
				transition: 1s ease-in;
				nav {
					ul.navbar-nav{
				    	position: absolute;
				    	top: 50%;
					    transform: translateY(-50%);
					    left: 0;
					    right: 0;
					    margin:0;
				    	li {
						    padding:5px 0;
						    text-align: center;
						    a {
						    	border-top:2px solid transparent;
						    	color: #fff;
						    	font-size: 16px;
						    	font-weight: 500;
						    	max-width: 150px;
								margin: auto;
						    	&:hover {
						    		color: #fff;
						    	}
						    	&:focus{
						    		color: #fff;
						    	}
						    	&.active{
						    		border-top:2px solid #fff;
						    	}
						    }
						}
				    }
					.region-navigation {
					    display: block;
					    position: fixed;
					    top: 25px;
					    left: 10px;
					    float: none;
					    max-width: 150px;
					    margin: auto;
					    background: transparent;
					}
				}
			}
		}
	}
	.navbar-nav > li {
	    float: none;
	}
	.navbar-default .navbar-toggle {
	    border-color: transparent;
	}
}
@media (min-width: 768px) and (max-width: 1200px){
	.page-node-13 .node-header-basic .field-type-image img, 
	.page-node-19 .node-header-basic .field-name-field-map-code iframe,
	.header-section .header-image img{
		width: 100%;
	}
	.header-section .header-image{
		width: 100%;
		float: none;
	}
}