body{
	font-size: 14px;
	font-family: 'Avenir';
	font-weight: 500;
	overflow-x: hidden;
}
.main-container{
	@media screen and (max-width: 767px){
		overflow-x: hidden;
	}
}
#block-system-main, .region{
	overflow: hidden;
}
a:hover, a:focus, button:hover, button:focus {
    color: inherit;
    text-decoration: none;
}
.navbar {
	margin-bottom: 0;
}
.navbar .logo {
    position: relative;
    top: -42px;
    z-index: 9;
    max-width: 280px;
    margin: 0;
    height: 75px;
    @media (min-width: 992px) and (max-width: 1170px){
    	top: -30px;
    	max-width: 300px;
    }
    @media screen and (max-width: 767px){
    	top: -20px;
    }
}
header{
	&.navbar-default{
		background-color: transparent;
		border: none;
		padding: 20px;
		.navbar-header{
			.name{
				&.navbar-brand{
					display: none;
				}
			}
			img {
				@media (min-width: 992px) and (max-width: 1170px) {
					max-width: 180px;
					padding: 10px 0;
				}
			}
		}
		.navbar-collapse{
			padding: 0;
			position:relative;
			@media screen and (max-width: 991px) {
				background-color: #000;
			    opacity: 0.9;
			    position: fixed;
			    z-index: 999999;
			    width: 100%;
			    left: 0;
			    height: 100%;
			    top: 0;
			    transition: 1s ease-in-out;
			    overflow: hidden ;
			}
			nav{
				float: right;
    			margin-top: 12px;
				ul{
					&.navbar-nav{			
						li{
							padding: 0 25px;
							@media (min-width: 992px) and (max-width: 1170px){
								padding: 0 7px;
							}
							@media (min-width: 1171px) and (max-width: 1300px){
								padding: 0 12px;
							}
							a {
								font-size: 14px;
								font-weight: 900;
								color: #151515;
								letter-spacing: 0.23px;
								border-top:2px solid transparent;
								&:hover {
									border-top:2px solid #000;
								}
								&.active{
									border-top:2px solid #000;
									@media screen and (max-width: 991px){
										border-top: 2px solid #fff;
									}
								}
							}
							a,a:hover,a:focus{
								background-color: transparent;
								color: #000;
								padding: 6px 0;
							}
						}					
					}
				}
				.region-navigation{
					float: right;
					margin-left: 20px;
					background-color: @grey;
					@media (min-width: 992px) and (max-width: 1170px){
						margin-left: 5px;
					}
					a,a:hover,a:focus{
						color: @white;
						text-decoration: none;
						display: block;
    					padding: 7px 10px;
    					.icon-Tl{
    						&:before{
    							color: #fff;
    							margin-right: 5px;
    						}
    					}
					}
					/*@media (min-width: 768px) and (max-width: 991px){
						position:absolute;
						top: 20px;
						right: 0;
					}*/
				}
			}
		}

		.navbar-toggle {
			z-index: 99999999999999999;
			.icon-bar {
			    background-color: #000;
			    width: 25px;
			    height: 2px;
			    border-radius: 35px;
			    &:nth-child(3),&:nth-child(5){
			    	width: 15px;
			    	margin-left: 10px;
			    }
			}
			&[aria-expanded="true"]{
				margin-right: 0;
				padding:15px 20px;
				.icon-bar{
					background-color: white;
					&:nth-child(3),
				    &:nth-child(5){
				    	display: none;
				    }
				    &:nth-child(4){
				    	transform: rotate(60deg);
					    position: fixed;
					    top: 30px;
					    width: 15px;
					    height: 2px;
				    }
				    &:nth-child(2){
				    	transform: rotate(-60deg);
					    position: fixed;
					    top: 34px;
					    width: 15px;
					    height: 2px;
				    }
				}
			}
			&[aria-expanded="false"]{
				.icon-bar{
					background-color: black;
					margin-right: 0;
				}
			}
		}
	}
}
.button{
    position: relative;
    text-align: center;
    display: inline-block;
    padding: 15px 60px 15px 15px;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 0.2px;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    @media (min-width: 768px) and (max-width: 991px){
    	padding:15px 43px 15px 15px;
    }
    &:before{
    	content: '\e905';
	    position: absolute;
	    right: 15px;
	    top: 17px;
	    font-family: 'icomoon';
	    transform: rotate(270deg);
	    font-size: 10px;
	    @media (min-width: 768px) and (max-width: 991px){
	    	right: 13px;
	    	top: 20px;
	    	font-size: 8px;
	    }
    }
}
/*.button-right-arrow{
	    color: #3a3a3c;
	    background-color: transparent;
	    border: 2px solid #3a3a3c;
	    position: relative;
	    bottom: auto;
	    right: auto;
    
    &:hover{
    	background: @grey;
	    color: #fff;
	    border: 2px solid @grey;
    }
}*/
/*.page-node-29{
	.button-right-arrow{
	    color: #3a3a3c;
	    background-color: transparent;
	    border: 2px solid #3a3a3c;
	    position: absolute;
	    bottom: 50px;
	    right: 35px;
	    @media screen and (max-width: 767px){
	    	position:relative;
	    	bottom:auto;
	    	padding: 15px 65px 15px 15px;
	    }
	    &:hover{
	    	background: @grey;
		    color: #fff;
		    border: 2px solid @grey;
	    }
	}
}*/
.btn-default:active{
	background:transparent;
}
#cboxClose {
    top: 0;
}