.owl-carousel-home{
	&.owl-carousel{		
    	.owl-wrapper{    		
    		.owl-item{
    			.views-row{
    				width: 68%;
    				float: right;
    				max-height: calc(100vh - 25vh);
    				@media screen and (max-width: 1200px){
    					width: 100%;
    					height: auto;
    					max-height: calc(100vh - 5vh);
    				}
	    			.img-header {
					    position: relative;
					    z-index: -1;
					    img{
				    	    height: auto;
						    @media screen and (max-width: 991px){
						    	height: auto;
						    }
					    }
					}
				}
    		}
    	}
		.owl-buttons{
			display: none;
		}
		.owl-controls{
			margin-top: 32px;
			@media screen and (max-width: 1200px){
				margin-top: 0;
			}
			.owl-page{
				span{
					background: transparent;
					border: 1px solid #D8D8D8;
					opacity: 1;
					margin: 5px;
				}
				&.active{
					span{
						background: #D8D8D8;
					}					
				}
			}
		}
	}		
}
.owl-theme .owl-controls .owl-buttons div{
	padding:10px 10px !important;
}
.owl-carousel-project-home{
	margin-top: 40px;
	.owl-buttons{
		display: none;
	}
	.field-name-node-link {
		.even {
			margin-bottom: 20px;
			@media screen and (max-width: 767px){
				margin: 25px 0 33px;
			}
		}
	}	
	.group-project-first-section{
		position:relative;
		background-color: @grey;
		height: auto;
		@media screen and (max-width: 767px){
			height: auto;
    		min-height: unset;
		}
		.container{
			@media (min-width: 768px) and (max-width: 1200px){
				padding: 0;
				margin: 0;
			}
		}
		.group-content{
			position: relative;
		    margin-top: 65px;
		    max-width: 420px;
		    background-color: #FFF;
		    padding: 5px 30px 10px 20px;
		    z-index: 5;
		    @media screen and (max-width: 767px){
		    	margin-top: 0;
			    bottom: 10px;
			    left: -15px;
			    max-width: 100%;
			    padding: 20px;
		    }
		    @media (min-width: 768px) and (max-width: 1200px){
		    	position: relative;
			    bottom: 15px;
			    left: 0;
			    max-width: 95%;
			    background-color: #FFF;
			    padding: 30px 70px 30px 50px;
			    z-index: 5;
			    margin-bottom: 0;
			    margin: 0;
		    }
		    &:after{
		    	content: '';
			    position: absolute;
			    width: 120px;
			    height: 10px;
			    background: @grey;
			    top: 50%;
			    right: -90px;
			    transform: translateY(-50%);
			    display: block;
			    @media screen and (max-width: 767px){
			    	display: none;
			    }
		    }
		    h2{
		    	font-size: 30px;
			    margin: 0;
			    font-weight: 900;
			    color: #4A4A4A;
			    letter-spacing: 3.23px;
			    line-height: 50px;
			    text-transform: uppercase;
			    @media screen and (max-width: 767px){
			    	font-size: 25px;
			    	line-height: 40px;
			    }
		    }
		    .field-name-field-ville{
		    	.field-item{
		    		font-size: 14px;
				    font-weight: 600;
				    margin-bottom: 10px;
				    color: #7B7B7B;
					letter-spacing: 0.2px;
				    &:before{
				    	content: "\e916";
					    font-family: 'icomoon' !important;
						font-weight: normal;
						margin-right: 5px;
				    }
		    	}
		    }
		    p {
		    	margin: 0;
			    margin-bottom: 10px;
			    color: #4A4A4A;
				letter-spacing: 0.2px;
		    }
		}
		.file-image{
			img{
				position: absolute;
			    width: calc(((100vw - 1170px) / 2) + 750px);
			    max-height: calc(100vh - 45vh);
			    object-fit: cover;
			    right: 0;
			    top: 0;
			    z-index: 1;
			    @media screen and (max-width: 1200px){
			    	width: 100%;
				    height: auto;
				    position:relative;
				    top: 0;
				    object-fit: unset;
				    max-height: none;
			    }
			}
		}			
	}
	.group-project-second-section{
		position:relative;
		min-height: 470px;
		.group-second-content{
			position: relative;
			margin-top: 20px;
			margin-left: 65px;
			max-width: 550px;
		    background-color: @grey;
		    padding: 30px 80px;
		    z-index: 2;
		    color: @white;
		    @media screen and (max-width: 767px){
		    	position: absolute;
			    margin: 0;
			    max-width: 90%;
			    padding: 30px;
			    left: 0;
			    bottom: -85px;
		    }
		    &:after{
		    	content: '';
			    position: absolute;
			    width: 120px;
			    height: 10px;
			    background: @white;
			    top: 50%;
			    right: -60px;
			    transform: translateY(-50%);
			    display: block;
			    @media screen and (max-width: 767px){
			    	display: none;
			    }
		    }
		    .field-name-field-second-titre{
		    	.field-item{
		    		font-size: 30px;
				    margin: 0;
				    font-weight: 900;
				    color: @white;
				    letter-spacing: 3.23px;
				    line-height: 61px;
				    @media screen and (max-width: 767px){
				    	font-size: 25px;
				    	line-height: 40px;
				    }
			    }
		    }
		    .field-name-field-seconde-description{
		    	margin-bottom: 15px;
		    }
		}
		.file-image{
			img{
				position: absolute;
			    width: calc(((100vw - 1170px) / 2) + 650px);
			    height: 500px;
			    object-fit: cover;
			    right: 0;
			    top: -30px;
			    z-index: 0;
			    @media screen and (max-width: 767px){
			    	width: 90%;
				    height: auto;
				    object-fit: cover;
				    right: 0;
				    top: -60px;
			    }
			}
		}		
	}
}
#block-views-f44cedcc72fae40138ee339f0e007add{
	.view-in-same-residence{
		.node-appartement{
			width: auto;
			margin: 0;
			@media screen and (max-width: 767px){
				padding: 0;
			}
			.col-appartement{
				.app-content{
					.row{
						.field{
							display: flex;
    						padding: 10px 0;
    						@media screen and (max-width: 767px){
    							display: block;
    						}
    						.field-label{
    							text-transform: uppercase;
    							font-size: 13px;
    						}
						}
					}
				}
			}
		}
	}
}